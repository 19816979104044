import React, {FC, useEffect, useState} from 'react';
import SendEmail from "../sendEmail/sendEmail";
import Btn from "../../ui/btn";
import {colors} from "../../ui";
import Flex from "../../ui/flex";
import SectionComerce from "./components/sectionComerce";
import SectionTransaction from "./components/sectionTransaction";
import SectionInvoices from "./components/sectionInvoices";
import SectionTitle from "./components/sectionTitle";
import {withRouter} from "react-router-dom";
import {GetResume} from "../Request/Request";
import {comercio, detalleTransaccion} from "./types";
import {
    extractComerceData,
    extractTransactionData
} from "./helper";
import {Container} from "./styles";
import {invoicesPaid} from "../../typesNames/typesNames";
import {MultiInvoicesPdf} from "../PDF/pdfMultiples";
import { formatInDetails, setNewTagCI360 } from '../../../utils/tagManager/ci360'

type  Props = {
    history: any
}

type State = {
    processing: boolean,
    gettingData: boolean
    comercio: comercio;
    detalleTransaccion: detalleTransaccion;
}

const ResumeMI: FC<Props> = (props) => {

    let refPaycoEmail = "";
    let empresaPago, tipoCliente, eventAction;

    let refPayco       : string = props.history.location.search;
    let refPaycoVal    : string = new URLSearchParams(refPayco).get('ref_payco') || '';
    let activeInvoices : any    = JSON.parse(sessionStorage.getItem(invoicesPaid)|| "null");
    sessionStorage.removeItem('dataPayment');

    let [state, setstate] = useState<State>({
        processing: false,
        gettingData: true,
        comercio: {
            empresa: "",
            nit: "",
            fechaHora: "",
            ipOrigen: "",
        },
        detalleTransaccion: {
            valor: "",
            estado: "",
            autorizacion: "",
            referencia: "",
        }
    });





    let GetDataResumen = async (ref: string) => {
        let response: any = await GetResume({
            "ref_payco": ref,
            "x_ref_payco": false
        });
        let {result} = response;
        let {success, data} = response.data
        if (result && success) {
            let ultraFacturas = {
                facturas: JSON.parse(sessionStorage.getItem("invoicesPaid") || "")
            }
            setNewTagCI360(formatInDetails(ultraFacturas, "factura-finalizar-compra"))
            setstate({
                ...state,
                comercio: extractComerceData(data),
                detalleTransaccion: extractTransactionData(data),
                gettingData: false
            })
        }

    }

    useEffect(() => {
        if( !activeInvoices || !activeInvoices.length) {
            props.history.push('/')
            return
        }
        GetDataResumen(refPaycoVal);
    }, [])

    let handler = (x: any) => setstate({...state, ...x});

    return (
        <Container className={"col-12 col-sm-10 col-md-10 col-lg-8 mx-auto"}>
            <div className="container-sm  py-3" style={{maxWidth:"800px"}}>
                <SectionTitle 
                    loading={state.gettingData}
                    transaction={state.detalleTransaccion.estado}
                />
                <Flex className={"wc pt-3"}>
                    <div className={"col-12 col-sm-6  pe-md-2"}>
                        <SectionComerce
                            loading={state.processing || state.gettingData}
                            data={state.comercio}
                        />
                    </div>
                    <div className={"col-12 col-sm-6  ps-md-2"}>
                        <SectionTransaction
                            loading={state.processing || state.gettingData}
                            data={state.detalleTransaccion}
                        />
                    </div>
                    <SectionInvoices
                        loading={state.processing || state.gettingData}
                        invoices={activeInvoices}
                    />
                </Flex>
                <Flex className={"wc"} alg={"flex-start"}>
                    <div className="col-12 pe-sm-3 col-md-6">
                        <SendEmail
                            setLoading={(val) => handler({proccesing: val})}
                            disabled={state.gettingData}
                            ref_payco={state.detalleTransaccion.referencia}
                            setTagManagerEmail={[
                                empresaPago,
                                tipoCliente,
                                eventAction
                            ]}

                        />
                    </div>
                    <div className="col-12 ps-sm-3 col-md-6">
                        <MultiInvoicesPdf
                            disabled={state.gettingData}
                            dataToRender={{
                                datacomerce:state.comercio,
                                dataTransaction:state.detalleTransaccion,
                                invoices:activeInvoices,
                            }}
                        />
                        <Btn
                            color={colors.blue}
                            onClick={() => props.history.push("/")}
                            flex={"1 0 100%"}
                            big
                            className={"fz18"}
                            disabled={state.processing || state.gettingData}
                        >
                            Finalizar transacción
                        </Btn>
                    </div>
                </Flex>
            </div>
        </Container>
    )
}

export default withRouter(React.memo(ResumeMI));