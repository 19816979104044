import React, {FC} from 'react';

type Props = {
    loading:boolean,
    transaction:string;
}

const SectionTitle:FC<Props> = (props) => {
    let {loading, transaction}= props;
    return (
        <div className={"col-12 text-center py-3"}>
            <p className={"mb-0 fz26 fl ct"}>
                {loading ? 'Procesando Datos de la transacción...':
                    <span>
                        Transacción {transaction}
                        {transaction === 'Aceptada' && <i className={"icon-icon-check"}></i>}
                    </span>}
            </p>
        </div>
    )
}

export default React.memo(SectionTitle);