const  getCities = () => {
    return [
        {
            "id": "1",
            "nombre": "Leticia",
            "ciudad_departamento": "Leticia (Amazonas)",
            "indicativo": "608",
            "departamento": "Amazonas"
        },
        {
            "id": "2",
            "nombre": "Puerto Nariño",
            "ciudad_departamento": "Puerto Nariño (Amazonas)",
            "indicativo": "608",
            "departamento": "Amazonas"
        },
        {
            "id": "3",
            "nombre": "Abejorral",
            "ciudad_departamento": "Abejorral (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "4",
            "nombre": "Abriaquí",
            "ciudad_departamento": "Abriaquí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "5",
            "nombre": "Alejandria",
            "ciudad_departamento": "Alejandria (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "6",
            "nombre": "Amagá",
            "ciudad_departamento": "Amagá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "7",
            "nombre": "Amalfi",
            "ciudad_departamento": "Amalfi (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "8",
            "nombre": "Andes",
            "ciudad_departamento": "Andes (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "9",
            "nombre": "Angelópolis",
            "ciudad_departamento": "Angelópolis (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "10",
            "nombre": "Angostura",
            "ciudad_departamento": "Angostura (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "11",
            "nombre": "Anorí",
            "ciudad_departamento": "Anorí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "12",
            "nombre": "Anzá",
            "ciudad_departamento": "Anzá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "13",
            "nombre": "Apartadó",
            "ciudad_departamento": "Apartadó (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "14",
            "nombre": "Arboletes",
            "ciudad_departamento": "Arboletes (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "15",
            "nombre": "Argelia",
            "ciudad_departamento": "Argelia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "16",
            "nombre": "Armenia",
            "ciudad_departamento": "Armenia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "17",
            "nombre": "Barbosa",
            "ciudad_departamento": "Barbosa (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "18",
            "nombre": "Bello",
            "ciudad_departamento": "Bello (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "19",
            "nombre": "Belmira",
            "ciudad_departamento": "Belmira (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "20",
            "nombre": "Betania",
            "ciudad_departamento": "Betania (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "21",
            "nombre": "Betulia",
            "ciudad_departamento": "Betulia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "22",
            "nombre": "Bolívar",
            "ciudad_departamento": "Bolívar (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "23",
            "nombre": "Briceño",
            "ciudad_departamento": "Briceño (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "24",
            "nombre": "Burítica",
            "ciudad_departamento": "Burítica (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "25",
            "nombre": "Caicedo",
            "ciudad_departamento": "Caicedo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "26",
            "nombre": "Caldas",
            "ciudad_departamento": "Caldas (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "27",
            "nombre": "Campamento",
            "ciudad_departamento": "Campamento (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "28",
            "nombre": "Caracolí",
            "ciudad_departamento": "Caracolí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "29",
            "nombre": "Caramanta",
            "ciudad_departamento": "Caramanta (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "30",
            "nombre": "Carepa",
            "ciudad_departamento": "Carepa (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "31",
            "nombre": "Carmen de Viboral",
            "ciudad_departamento": "Carmen de Viboral (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "32",
            "nombre": "Carolina",
            "ciudad_departamento": "Carolina (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "33",
            "nombre": "Caucasia",
            "ciudad_departamento": "Caucasia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "34",
            "nombre": "Cañasgordas",
            "ciudad_departamento": "Cañasgordas (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "35",
            "nombre": "Chigorodó",
            "ciudad_departamento": "Chigorodó (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "36",
            "nombre": "Cisneros",
            "ciudad_departamento": "Cisneros (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "37",
            "nombre": "Cocorná",
            "ciudad_departamento": "Cocorná (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "38",
            "nombre": "Concepción",
            "ciudad_departamento": "Concepción (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "39",
            "nombre": "Concordia",
            "ciudad_departamento": "Concordia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "40",
            "nombre": "Copacabana",
            "ciudad_departamento": "Copacabana (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "41",
            "nombre": "Cáceres",
            "ciudad_departamento": "Cáceres (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "42",
            "nombre": "Dabeiba",
            "ciudad_departamento": "Dabeiba (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "43",
            "nombre": "Don Matías",
            "ciudad_departamento": "Don Matías (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "44",
            "nombre": "Ebéjico",
            "ciudad_departamento": "Ebéjico (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "45",
            "nombre": "El Bagre",
            "ciudad_departamento": "El Bagre (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "46",
            "nombre": "Entrerríos",
            "ciudad_departamento": "Entrerríos (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "47",
            "nombre": "Envigado",
            "ciudad_departamento": "Envigado (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "48",
            "nombre": "Fredonia",
            "ciudad_departamento": "Fredonia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "49",
            "nombre": "Frontino",
            "ciudad_departamento": "Frontino (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "50",
            "nombre": "Giraldo",
            "ciudad_departamento": "Giraldo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "51",
            "nombre": "Girardota",
            "ciudad_departamento": "Girardota (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "52",
            "nombre": "Granada",
            "ciudad_departamento": "Granada (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "53",
            "nombre": "Guadalupe",
            "ciudad_departamento": "Guadalupe (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "54",
            "nombre": "Guarne",
            "ciudad_departamento": "Guarne (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "55",
            "nombre": "Guatapé",
            "ciudad_departamento": "Guatapé (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "56",
            "nombre": "Gómez Plata",
            "ciudad_departamento": "Gómez Plata (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "57",
            "nombre": "Heliconia",
            "ciudad_departamento": "Heliconia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "58",
            "nombre": "Hispania",
            "ciudad_departamento": "Hispania (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "59",
            "nombre": "Itagüí",
            "ciudad_departamento": "Itagüí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "60",
            "nombre": "Ituango",
            "ciudad_departamento": "Ituango (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "61",
            "nombre": "Jardín",
            "ciudad_departamento": "Jardín (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "62",
            "nombre": "Jericó",
            "ciudad_departamento": "Jericó (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "63",
            "nombre": "La Ceja",
            "ciudad_departamento": "La Ceja (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "64",
            "nombre": "La Estrella",
            "ciudad_departamento": "La Estrella (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "65",
            "nombre": "La Pintada",
            "ciudad_departamento": "La Pintada (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "66",
            "nombre": "La Unión",
            "ciudad_departamento": "La Unión (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "67",
            "nombre": "Liborina",
            "ciudad_departamento": "Liborina (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "68",
            "nombre": "Maceo",
            "ciudad_departamento": "Maceo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "69",
            "nombre": "Marinilla",
            "ciudad_departamento": "Marinilla (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "70",
            "nombre": "Medellín",
            "ciudad_departamento": "Medellín (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "71",
            "nombre": "Montebello",
            "ciudad_departamento": "Montebello (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "72",
            "nombre": "Murindó",
            "ciudad_departamento": "Murindó (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "73",
            "nombre": "Mutatá",
            "ciudad_departamento": "Mutatá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "74",
            "nombre": "Nariño",
            "ciudad_departamento": "Nariño (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "75",
            "nombre": "Nechí",
            "ciudad_departamento": "Nechí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "76",
            "nombre": "Necoclí",
            "ciudad_departamento": "Necoclí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "77",
            "nombre": "Olaya",
            "ciudad_departamento": "Olaya (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "78",
            "nombre": "Peque",
            "ciudad_departamento": "Peque (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "79",
            "nombre": "Peñol",
            "ciudad_departamento": "Peñol (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "80",
            "nombre": "Pueblorrico",
            "ciudad_departamento": "Pueblorrico (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "81",
            "nombre": "Puerto Berrío",
            "ciudad_departamento": "Puerto Berrío (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "82",
            "nombre": "Puerto Nare",
            "ciudad_departamento": "Puerto Nare (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "83",
            "nombre": "Puerto Triunfo",
            "ciudad_departamento": "Puerto Triunfo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "84",
            "nombre": "Remedios",
            "ciudad_departamento": "Remedios (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "85",
            "nombre": "Retiro",
            "ciudad_departamento": "Retiro (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "87",
            "nombre": "Sabanalarga",
            "ciudad_departamento": "Sabanalarga (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "88",
            "nombre": "Sabaneta",
            "ciudad_departamento": "Sabaneta (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "89",
            "nombre": "Salgar",
            "ciudad_departamento": "Salgar (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "90",
            "nombre": "San Andrés de Cuerquía",
            "ciudad_departamento": "San Andrés de Cuerquía (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "91",
            "nombre": "San Carlos",
            "ciudad_departamento": "San Carlos (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "92",
            "nombre": "San Francisco",
            "ciudad_departamento": "San Francisco (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "93",
            "nombre": "San Jerónimo",
            "ciudad_departamento": "San Jerónimo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "94",
            "nombre": "San José de Montaña",
            "ciudad_departamento": "San José de Montaña (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "95",
            "nombre": "San Juan de Urabá",
            "ciudad_departamento": "San Juan de Urabá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "96",
            "nombre": "San Luís",
            "ciudad_departamento": "San Luís (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "97",
            "nombre": "San Pedro",
            "ciudad_departamento": "San Pedro (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "98",
            "nombre": "San Pedro de Urabá",
            "ciudad_departamento": "San Pedro de Urabá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "99",
            "nombre": "San Rafael",
            "ciudad_departamento": "San Rafael (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "100",
            "nombre": "San Roque",
            "ciudad_departamento": "San Roque (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "101",
            "nombre": "San Vicente",
            "ciudad_departamento": "San Vicente (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "102",
            "nombre": "Santa Bárbara",
            "ciudad_departamento": "Santa Bárbara (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "103",
            "nombre": "Santa Fé de Antioquia",
            "ciudad_departamento": "Santa Fé de Antioquia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "104",
            "nombre": "Santa Rosa de Osos",
            "ciudad_departamento": "Santa Rosa de Osos (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "105",
            "nombre": "Santo Domingo",
            "ciudad_departamento": "Santo Domingo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "106",
            "nombre": "Santuario",
            "ciudad_departamento": "Santuario (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "107",
            "nombre": "Segovia",
            "ciudad_departamento": "Segovia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "108",
            "nombre": "Sonsón",
            "ciudad_departamento": "Sonsón (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "109",
            "nombre": "Sopetrán",
            "ciudad_departamento": "Sopetrán (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "110",
            "nombre": "Tarazá",
            "ciudad_departamento": "Tarazá (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "111",
            "nombre": "Tarso",
            "ciudad_departamento": "Tarso (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "112",
            "nombre": "Titiribí",
            "ciudad_departamento": "Titiribí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "113",
            "nombre": "Toledo",
            "ciudad_departamento": "Toledo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "114",
            "nombre": "Turbo",
            "ciudad_departamento": "Turbo (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "115",
            "nombre": "Támesis",
            "ciudad_departamento": "Támesis (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "116",
            "nombre": "Uramita",
            "ciudad_departamento": "Uramita (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "117",
            "nombre": "Urrao",
            "ciudad_departamento": "Urrao (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "118",
            "nombre": "Valdivia",
            "ciudad_departamento": "Valdivia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "119",
            "nombre": "Valparaiso",
            "ciudad_departamento": "Valparaiso (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "120",
            "nombre": "Vegachí",
            "ciudad_departamento": "Vegachí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "121",
            "nombre": "Venecia",
            "ciudad_departamento": "Venecia (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "122",
            "nombre": "Vigía del Fuerte",
            "ciudad_departamento": "Vigía del Fuerte (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "123",
            "nombre": "Yalí",
            "ciudad_departamento": "Yalí (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "124",
            "nombre": "Yarumal",
            "ciudad_departamento": "Yarumal (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "125",
            "nombre": "Yolombó",
            "ciudad_departamento": "Yolombó (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "126",
            "nombre": "Yondó (Casabe)",
            "ciudad_departamento": "Yondó (Casabe) (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "127",
            "nombre": "Zaragoza",
            "ciudad_departamento": "Zaragoza (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "10002",
            "nombre": "Ciudad Bolívar",
            "ciudad_departamento": "Ciudad Bolívar (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        },
        {
            "id": "128",
            "nombre": "Arauca",
            "ciudad_departamento": "Arauca (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "129",
            "nombre": "Arauquita",
            "ciudad_departamento": "Arauquita (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "130",
            "nombre": "Cravo Norte",
            "ciudad_departamento": "Cravo Norte (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "131",
            "nombre": "Fortúl",
            "ciudad_departamento": "Fortúl (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "132",
            "nombre": "Puerto Rondón",
            "ciudad_departamento": "Puerto Rondón (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "133",
            "nombre": "Saravena",
            "ciudad_departamento": "Saravena (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "134",
            "nombre": "Tame",
            "ciudad_departamento": "Tame (Arauca)",
            "indicativo": "607",
            "departamento": "Arauca"
        },
        {
            "id": "135",
            "nombre": "Baranoa",
            "ciudad_departamento": "Baranoa (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "136",
            "nombre": "Barranquilla",
            "ciudad_departamento": "Barranquilla (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "137",
            "nombre": "Campo de la Cruz",
            "ciudad_departamento": "Campo de la Cruz (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "138",
            "nombre": "Candelaria",
            "ciudad_departamento": "Candelaria (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "139",
            "nombre": "Galapa",
            "ciudad_departamento": "Galapa (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "140",
            "nombre": "Juan de Acosta",
            "ciudad_departamento": "Juan de Acosta (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "141",
            "nombre": "Luruaco",
            "ciudad_departamento": "Luruaco (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "142",
            "nombre": "Malambo",
            "ciudad_departamento": "Malambo (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "143",
            "nombre": "Manatí",
            "ciudad_departamento": "Manatí (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "144",
            "nombre": "Palmar de Varela",
            "ciudad_departamento": "Palmar de Varela (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "145",
            "nombre": "Piojo",
            "ciudad_departamento": "Piojo (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "146",
            "nombre": "Polonuevo",
            "ciudad_departamento": "Polonuevo (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "147",
            "nombre": "Ponedera",
            "ciudad_departamento": "Ponedera (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "148",
            "nombre": "Puerto Colombia",
            "ciudad_departamento": "Puerto Colombia (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "149",
            "nombre": "Repelón",
            "ciudad_departamento": "Repelón (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "150",
            "nombre": "Sabanagrande",
            "ciudad_departamento": "Sabanagrande (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "151",
            "nombre": "Sabanalarga",
            "ciudad_departamento": "Sabanalarga (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "152",
            "nombre": "Santa Lucía",
            "ciudad_departamento": "Santa Lucía (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "153",
            "nombre": "Santo Tomás",
            "ciudad_departamento": "Santo Tomás (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "154",
            "nombre": "Soledad",
            "ciudad_departamento": "Soledad (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "155",
            "nombre": "Suan",
            "ciudad_departamento": "Suan (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "156",
            "nombre": "Tubará",
            "ciudad_departamento": "Tubará (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "157",
            "nombre": "Usiacuri",
            "ciudad_departamento": "Usiacuri (Atlántico)",
            "indicativo": "605",
            "departamento": "Atlántico"
        },
        {
            "id": "158",
            "nombre": "Achí",
            "ciudad_departamento": "Achí (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "159",
            "nombre": "Altos del Rosario",
            "ciudad_departamento": "Altos del Rosario (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "160",
            "nombre": "Arenal",
            "ciudad_departamento": "Arenal (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "161",
            "nombre": "Arjona",
            "ciudad_departamento": "Arjona (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "162",
            "nombre": "Arroyohondo",
            "ciudad_departamento": "Arroyohondo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "163",
            "nombre": "Barranco de Loba",
            "ciudad_departamento": "Barranco de Loba (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "164",
            "nombre": "Calamar",
            "ciudad_departamento": "Calamar (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "165",
            "nombre": "Cantagallo",
            "ciudad_departamento": "Cantagallo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "166",
            "nombre": "Cartagena",
            "ciudad_departamento": "Cartagena (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "167",
            "nombre": "Cicuco",
            "ciudad_departamento": "Cicuco (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "168",
            "nombre": "Clemencia",
            "ciudad_departamento": "Clemencia (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "169",
            "nombre": "Córdoba",
            "ciudad_departamento": "Córdoba (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "170",
            "nombre": "El Carmen de Bolívar",
            "ciudad_departamento": "El Carmen de Bolívar (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "171",
            "nombre": "El Guamo",
            "ciudad_departamento": "El Guamo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "172",
            "nombre": "El Peñon",
            "ciudad_departamento": "El Peñon (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "173",
            "nombre": "Hatillo de Loba",
            "ciudad_departamento": "Hatillo de Loba (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "174",
            "nombre": "Magangué",
            "ciudad_departamento": "Magangué (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "175",
            "nombre": "Mahates",
            "ciudad_departamento": "Mahates (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "176",
            "nombre": "Margarita",
            "ciudad_departamento": "Margarita (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "177",
            "nombre": "María la Baja",
            "ciudad_departamento": "María la Baja (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "178",
            "nombre": "Mompós",
            "ciudad_departamento": "Mompós (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "179",
            "nombre": "Montecristo",
            "ciudad_departamento": "Montecristo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "180",
            "nombre": "Morales",
            "ciudad_departamento": "Morales (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "181",
            "nombre": "Norosí",
            "ciudad_departamento": "Norosí (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "182",
            "nombre": "Pinillos",
            "ciudad_departamento": "Pinillos (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "183",
            "nombre": "Regidor",
            "ciudad_departamento": "Regidor (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "184",
            "nombre": "Río Viejo",
            "ciudad_departamento": "Río Viejo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "185",
            "nombre": "San Cristobal",
            "ciudad_departamento": "San Cristobal (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "186",
            "nombre": "San Estanislao",
            "ciudad_departamento": "San Estanislao (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "187",
            "nombre": "San Fernando",
            "ciudad_departamento": "San Fernando (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "188",
            "nombre": "San Jacinto",
            "ciudad_departamento": "San Jacinto (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "189",
            "nombre": "San Jacinto del Cauca",
            "ciudad_departamento": "San Jacinto del Cauca (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "190",
            "nombre": "San Juan de Nepomuceno",
            "ciudad_departamento": "San Juan de Nepomuceno (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "191",
            "nombre": "San Martín de Loba",
            "ciudad_departamento": "San Martín de Loba (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "192",
            "nombre": "San Pablo",
            "ciudad_departamento": "San Pablo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "193",
            "nombre": "Santa Catalina",
            "ciudad_departamento": "Santa Catalina (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "194",
            "nombre": "Santa Rosa ",
            "ciudad_departamento": "Santa Rosa  (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "195",
            "nombre": "Santa Rosa del Sur",
            "ciudad_departamento": "Santa Rosa del Sur (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "196",
            "nombre": "Simití",
            "ciudad_departamento": "Simití (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "197",
            "nombre": "Soplaviento",
            "ciudad_departamento": "Soplaviento (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "198",
            "nombre": "Talaigua Nuevo",
            "ciudad_departamento": "Talaigua Nuevo (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "199",
            "nombre": "Tiquisio (Puerto Rico)",
            "ciudad_departamento": "Tiquisio (Puerto Rico) (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "200",
            "nombre": "Turbaco",
            "ciudad_departamento": "Turbaco (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "201",
            "nombre": "Turbaná",
            "ciudad_departamento": "Turbaná (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "202",
            "nombre": "Villanueva",
            "ciudad_departamento": "Villanueva (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "203",
            "nombre": "Zambrano",
            "ciudad_departamento": "Zambrano (Bolívar)",
            "indicativo": "605",
            "departamento": "Bolívar"
        },
        {
            "id": "204",
            "nombre": "Almeida",
            "ciudad_departamento": "Almeida (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "205",
            "nombre": "Aquitania",
            "ciudad_departamento": "Aquitania (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "206",
            "nombre": "Arcabuco",
            "ciudad_departamento": "Arcabuco (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "207",
            "nombre": "Belén",
            "ciudad_departamento": "Belén (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "208",
            "nombre": "Berbeo",
            "ciudad_departamento": "Berbeo (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "209",
            "nombre": "Beteitiva",
            "ciudad_departamento": "Beteitiva (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "210",
            "nombre": "Boavita",
            "ciudad_departamento": "Boavita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "211",
            "nombre": "Boyacá",
            "ciudad_departamento": "Boyacá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "212",
            "nombre": "Briceño",
            "ciudad_departamento": "Briceño (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "213",
            "nombre": "Buenavista",
            "ciudad_departamento": "Buenavista (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "214",
            "nombre": "Busbanza",
            "ciudad_departamento": "Busbanza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "215",
            "nombre": "Caldas",
            "ciudad_departamento": "Caldas (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "216",
            "nombre": "Campohermoso",
            "ciudad_departamento": "Campohermoso (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "217",
            "nombre": "Cerinza",
            "ciudad_departamento": "Cerinza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "218",
            "nombre": "Chinavita",
            "ciudad_departamento": "Chinavita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "219",
            "nombre": "Chiquinquirá",
            "ciudad_departamento": "Chiquinquirá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "220",
            "nombre": "Chiscas",
            "ciudad_departamento": "Chiscas (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "221",
            "nombre": "Chita",
            "ciudad_departamento": "Chita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "222",
            "nombre": "Chitaraque",
            "ciudad_departamento": "Chitaraque (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "223",
            "nombre": "Chivatá",
            "ciudad_departamento": "Chivatá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "224",
            "nombre": "Chíquiza",
            "ciudad_departamento": "Chíquiza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "225",
            "nombre": "Chívor",
            "ciudad_departamento": "Chívor (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "226",
            "nombre": "Ciénaga",
            "ciudad_departamento": "Ciénaga (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "227",
            "nombre": "Coper",
            "ciudad_departamento": "Coper (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "228",
            "nombre": "Corrales",
            "ciudad_departamento": "Corrales (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "229",
            "nombre": "Covarachía",
            "ciudad_departamento": "Covarachía (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "230",
            "nombre": "Cubará",
            "ciudad_departamento": "Cubará (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "231",
            "nombre": "Cucaita",
            "ciudad_departamento": "Cucaita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "232",
            "nombre": "Cuitiva",
            "ciudad_departamento": "Cuitiva (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "233",
            "nombre": "Cómbita",
            "ciudad_departamento": "Cómbita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "234",
            "nombre": "Duitama",
            "ciudad_departamento": "Duitama (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "235",
            "nombre": "El Cocuy",
            "ciudad_departamento": "El Cocuy (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "236",
            "nombre": "El Espino",
            "ciudad_departamento": "El Espino (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "237",
            "nombre": "Firavitoba",
            "ciudad_departamento": "Firavitoba (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "238",
            "nombre": "Floresta",
            "ciudad_departamento": "Floresta (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "239",
            "nombre": "Gachantivá",
            "ciudad_departamento": "Gachantivá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "240",
            "nombre": "Garagoa",
            "ciudad_departamento": "Garagoa (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "241",
            "nombre": "Guacamayas",
            "ciudad_departamento": "Guacamayas (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "242",
            "nombre": "Guateque",
            "ciudad_departamento": "Guateque (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "243",
            "nombre": "Guayatá",
            "ciudad_departamento": "Guayatá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "244",
            "nombre": "Guicán",
            "ciudad_departamento": "Guicán (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "245",
            "nombre": "Gámeza",
            "ciudad_departamento": "Gámeza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "246",
            "nombre": "Izá",
            "ciudad_departamento": "Izá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "247",
            "nombre": "Jenesano",
            "ciudad_departamento": "Jenesano (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "248",
            "nombre": "Jericó",
            "ciudad_departamento": "Jericó (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "249",
            "nombre": "La Capilla",
            "ciudad_departamento": "La Capilla (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "250",
            "nombre": "La Uvita",
            "ciudad_departamento": "La Uvita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "251",
            "nombre": "La Victoria",
            "ciudad_departamento": "La Victoria (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "252",
            "nombre": "Labranzagrande",
            "ciudad_departamento": "Labranzagrande (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "253",
            "nombre": "Macanal",
            "ciudad_departamento": "Macanal (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "254",
            "nombre": "Maripí",
            "ciudad_departamento": "Maripí (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "255",
            "nombre": "Miraflores",
            "ciudad_departamento": "Miraflores (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "256",
            "nombre": "Mongua",
            "ciudad_departamento": "Mongua (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "257",
            "nombre": "Monguí",
            "ciudad_departamento": "Monguí (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "258",
            "nombre": "Moniquirá",
            "ciudad_departamento": "Moniquirá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "259",
            "nombre": "Motavita",
            "ciudad_departamento": "Motavita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "260",
            "nombre": "Muzo",
            "ciudad_departamento": "Muzo (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "261",
            "nombre": "Nobsa",
            "ciudad_departamento": "Nobsa (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "262",
            "nombre": "Nuevo Colón",
            "ciudad_departamento": "Nuevo Colón (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "263",
            "nombre": "Oicatá",
            "ciudad_departamento": "Oicatá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "264",
            "nombre": "Otanche",
            "ciudad_departamento": "Otanche (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "265",
            "nombre": "Pachavita",
            "ciudad_departamento": "Pachavita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "266",
            "nombre": "Paipa",
            "ciudad_departamento": "Paipa (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "267",
            "nombre": "Pajarito",
            "ciudad_departamento": "Pajarito (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "268",
            "nombre": "Panqueba",
            "ciudad_departamento": "Panqueba (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "269",
            "nombre": "Pauna",
            "ciudad_departamento": "Pauna (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "270",
            "nombre": "Paya",
            "ciudad_departamento": "Paya (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "271",
            "nombre": "Paz de Río",
            "ciudad_departamento": "Paz de Río (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "272",
            "nombre": "Pesca",
            "ciudad_departamento": "Pesca (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "273",
            "nombre": "Pisva",
            "ciudad_departamento": "Pisva (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "274",
            "nombre": "Puerto Boyacá",
            "ciudad_departamento": "Puerto Boyacá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "275",
            "nombre": "Páez",
            "ciudad_departamento": "Páez (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "276",
            "nombre": "Quipama",
            "ciudad_departamento": "Quipama (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "277",
            "nombre": "Ramiriquí",
            "ciudad_departamento": "Ramiriquí (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "278",
            "nombre": "Rondón",
            "ciudad_departamento": "Rondón (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "279",
            "nombre": "Ráquira",
            "ciudad_departamento": "Ráquira (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "280",
            "nombre": "Saboyá",
            "ciudad_departamento": "Saboyá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "281",
            "nombre": "Samacá",
            "ciudad_departamento": "Samacá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "282",
            "nombre": "San Eduardo",
            "ciudad_departamento": "San Eduardo (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "283",
            "nombre": "San José de Pare",
            "ciudad_departamento": "San José de Pare (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "284",
            "nombre": "San Luís de Gaceno",
            "ciudad_departamento": "San Luís de Gaceno (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "285",
            "nombre": "San Mateo",
            "ciudad_departamento": "San Mateo (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "286",
            "nombre": "San Miguel de Sema",
            "ciudad_departamento": "San Miguel de Sema (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "287",
            "nombre": "San Pablo de Borbur",
            "ciudad_departamento": "San Pablo de Borbur (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "288",
            "nombre": "Santa María",
            "ciudad_departamento": "Santa María (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "289",
            "nombre": "Santa Rosa de Viterbo",
            "ciudad_departamento": "Santa Rosa de Viterbo (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "290",
            "nombre": "Santa Sofía",
            "ciudad_departamento": "Santa Sofía (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "291",
            "nombre": "Santana",
            "ciudad_departamento": "Santana (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "292",
            "nombre": "Sativanorte",
            "ciudad_departamento": "Sativanorte (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "293",
            "nombre": "Sativasur",
            "ciudad_departamento": "Sativasur (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "294",
            "nombre": "Siachoque",
            "ciudad_departamento": "Siachoque (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "295",
            "nombre": "Soatá",
            "ciudad_departamento": "Soatá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "296",
            "nombre": "Socha",
            "ciudad_departamento": "Socha (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "297",
            "nombre": "Socotá",
            "ciudad_departamento": "Socotá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "298",
            "nombre": "Sogamoso",
            "ciudad_departamento": "Sogamoso (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "299",
            "nombre": "Somondoco",
            "ciudad_departamento": "Somondoco (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "300",
            "nombre": "Sora",
            "ciudad_departamento": "Sora (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "301",
            "nombre": "Soracá",
            "ciudad_departamento": "Soracá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "302",
            "nombre": "Sotaquirá",
            "ciudad_departamento": "Sotaquirá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "303",
            "nombre": "Susacón",
            "ciudad_departamento": "Susacón (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "304",
            "nombre": "Sutamarchán",
            "ciudad_departamento": "Sutamarchán (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "305",
            "nombre": "Sutatenza",
            "ciudad_departamento": "Sutatenza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "306",
            "nombre": "Sáchica",
            "ciudad_departamento": "Sáchica (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "307",
            "nombre": "Tasco",
            "ciudad_departamento": "Tasco (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "308",
            "nombre": "Tenza",
            "ciudad_departamento": "Tenza (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "309",
            "nombre": "Tibaná",
            "ciudad_departamento": "Tibaná (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "310",
            "nombre": "Tibasosa",
            "ciudad_departamento": "Tibasosa (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "311",
            "nombre": "Tinjacá",
            "ciudad_departamento": "Tinjacá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "312",
            "nombre": "Tipacoque",
            "ciudad_departamento": "Tipacoque (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "313",
            "nombre": "Toca",
            "ciudad_departamento": "Toca (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "314",
            "nombre": "Toguí",
            "ciudad_departamento": "Toguí (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "315",
            "nombre": "Topagá",
            "ciudad_departamento": "Topagá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "316",
            "nombre": "Tota",
            "ciudad_departamento": "Tota (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "317",
            "nombre": "Tunja",
            "ciudad_departamento": "Tunja (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "318",
            "nombre": "Tunungua",
            "ciudad_departamento": "Tunungua (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "319",
            "nombre": "Turmequé",
            "ciudad_departamento": "Turmequé (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "320",
            "nombre": "Tuta",
            "ciudad_departamento": "Tuta (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "321",
            "nombre": "Tutasá",
            "ciudad_departamento": "Tutasá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "322",
            "nombre": "Ventaquemada",
            "ciudad_departamento": "Ventaquemada (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "323",
            "nombre": "Villa de Leiva",
            "ciudad_departamento": "Villa de Leiva (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "324",
            "nombre": "Viracachá",
            "ciudad_departamento": "Viracachá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "325",
            "nombre": "Zetaquirá",
            "ciudad_departamento": "Zetaquirá (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "326",
            "nombre": "Úmbita",
            "ciudad_departamento": "Úmbita (Boyacá)",
            "indicativo": "608",
            "departamento": "Boyacá"
        },
        {
            "id": "327",
            "nombre": "Aguadas",
            "ciudad_departamento": "Aguadas (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "328",
            "nombre": "Anserma",
            "ciudad_departamento": "Anserma (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "329",
            "nombre": "Aranzazu",
            "ciudad_departamento": "Aranzazu (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "330",
            "nombre": "Belalcázar",
            "ciudad_departamento": "Belalcázar (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "331",
            "nombre": "Chinchiná",
            "ciudad_departamento": "Chinchiná (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "332",
            "nombre": "Filadelfia",
            "ciudad_departamento": "Filadelfia (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "333",
            "nombre": "La Dorada",
            "ciudad_departamento": "La Dorada (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "334",
            "nombre": "La Merced",
            "ciudad_departamento": "La Merced (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "335",
            "nombre": "La Victoria",
            "ciudad_departamento": "La Victoria (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "336",
            "nombre": "Manizales",
            "ciudad_departamento": "Manizales (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "337",
            "nombre": "Manzanares",
            "ciudad_departamento": "Manzanares (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "338",
            "nombre": "Marmato",
            "ciudad_departamento": "Marmato (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "339",
            "nombre": "Marquetalia",
            "ciudad_departamento": "Marquetalia (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "340",
            "nombre": "Marulanda",
            "ciudad_departamento": "Marulanda (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "341",
            "nombre": "Neira",
            "ciudad_departamento": "Neira (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "342",
            "nombre": "Norcasia",
            "ciudad_departamento": "Norcasia (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "343",
            "nombre": "Palestina",
            "ciudad_departamento": "Palestina (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "344",
            "nombre": "Pensilvania",
            "ciudad_departamento": "Pensilvania (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "345",
            "nombre": "Pácora",
            "ciudad_departamento": "Pácora (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "346",
            "nombre": "Risaralda",
            "ciudad_departamento": "Risaralda (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "347",
            "nombre": "Río Sucio",
            "ciudad_departamento": "Río Sucio (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "348",
            "nombre": "Salamina",
            "ciudad_departamento": "Salamina (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "349",
            "nombre": "Samaná",
            "ciudad_departamento": "Samaná (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "350",
            "nombre": "San José",
            "ciudad_departamento": "San José (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "351",
            "nombre": "Supía",
            "ciudad_departamento": "Supía (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "352",
            "nombre": "Villamaría",
            "ciudad_departamento": "Villamaría (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "353",
            "nombre": "Viterbo",
            "ciudad_departamento": "Viterbo (Caldas)",
            "indicativo": "606",
            "departamento": "Caldas"
        },
        {
            "id": "354",
            "nombre": "Albania",
            "ciudad_departamento": "Albania (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "355",
            "nombre": "Belén de los Andaquíes",
            "ciudad_departamento": "Belén de los Andaquíes (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "356",
            "nombre": "Cartagena del Chairá",
            "ciudad_departamento": "Cartagena del Chairá (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "357",
            "nombre": "Curillo",
            "ciudad_departamento": "Curillo (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "358",
            "nombre": "El Doncello",
            "ciudad_departamento": "El Doncello (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "359",
            "nombre": "El Paujil",
            "ciudad_departamento": "El Paujil (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "360",
            "nombre": "Florencia",
            "ciudad_departamento": "Florencia (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "361",
            "nombre": "La Montañita",
            "ciudad_departamento": "La Montañita (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "362",
            "nombre": "Milán",
            "ciudad_departamento": "Milán (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "363",
            "nombre": "Morelia",
            "ciudad_departamento": "Morelia (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "364",
            "nombre": "Puerto Rico",
            "ciudad_departamento": "Puerto Rico (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "365",
            "nombre": "San José del Fragua",
            "ciudad_departamento": "San José del Fragua (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "366",
            "nombre": "San Vicente del Caguán",
            "ciudad_departamento": "San Vicente del Caguán (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "367",
            "nombre": "Solano",
            "ciudad_departamento": "Solano (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "368",
            "nombre": "Solita",
            "ciudad_departamento": "Solita (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "369",
            "nombre": "Valparaiso",
            "ciudad_departamento": "Valparaiso (Caquetá)",
            "indicativo": "608",
            "departamento": "Caquetá"
        },
        {
            "id": "370",
            "nombre": "Aguazul",
            "ciudad_departamento": "Aguazul (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "371",
            "nombre": "Chámeza",
            "ciudad_departamento": "Chámeza (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "372",
            "nombre": "Hato Corozal",
            "ciudad_departamento": "Hato Corozal (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "373",
            "nombre": "La Salina",
            "ciudad_departamento": "La Salina (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "374",
            "nombre": "Maní",
            "ciudad_departamento": "Maní (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "375",
            "nombre": "Monterrey",
            "ciudad_departamento": "Monterrey (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "376",
            "nombre": "Nunchía",
            "ciudad_departamento": "Nunchía (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "377",
            "nombre": "Orocué",
            "ciudad_departamento": "Orocué (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "378",
            "nombre": "Paz de Ariporo",
            "ciudad_departamento": "Paz de Ariporo (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "379",
            "nombre": "Pore",
            "ciudad_departamento": "Pore (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "380",
            "nombre": "Recetor",
            "ciudad_departamento": "Recetor (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "381",
            "nombre": "Sabanalarga",
            "ciudad_departamento": "Sabanalarga (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "382",
            "nombre": "San Luís de Palenque",
            "ciudad_departamento": "San Luís de Palenque (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "383",
            "nombre": "Sácama",
            "ciudad_departamento": "Sácama (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "384",
            "nombre": "Tauramena",
            "ciudad_departamento": "Tauramena (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "385",
            "nombre": "Trinidad",
            "ciudad_departamento": "Trinidad (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "386",
            "nombre": "Támara",
            "ciudad_departamento": "Támara (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "387",
            "nombre": "Villanueva",
            "ciudad_departamento": "Villanueva (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "388",
            "nombre": "Yopal",
            "ciudad_departamento": "Yopal (Casanare)",
            "indicativo": "608",
            "departamento": "Casanare"
        },
        {
            "id": "389",
            "nombre": "Almaguer",
            "ciudad_departamento": "Almaguer (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "390",
            "nombre": "Argelia",
            "ciudad_departamento": "Argelia (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "391",
            "nombre": "Balboa",
            "ciudad_departamento": "Balboa (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "392",
            "nombre": "Bolívar",
            "ciudad_departamento": "Bolívar (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "393",
            "nombre": "Buenos Aires",
            "ciudad_departamento": "Buenos Aires (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "394",
            "nombre": "Cajibío",
            "ciudad_departamento": "Cajibío (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "395",
            "nombre": "Caldono",
            "ciudad_departamento": "Caldono (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "396",
            "nombre": "Caloto",
            "ciudad_departamento": "Caloto (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "397",
            "nombre": "Corinto",
            "ciudad_departamento": "Corinto (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "398",
            "nombre": "El Tambo",
            "ciudad_departamento": "El Tambo (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "399",
            "nombre": "Florencia",
            "ciudad_departamento": "Florencia (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "400",
            "nombre": "Guachené",
            "ciudad_departamento": "Guachené (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "401",
            "nombre": "Guapí",
            "ciudad_departamento": "Guapí (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "402",
            "nombre": "Inzá",
            "ciudad_departamento": "Inzá (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "403",
            "nombre": "Jambaló",
            "ciudad_departamento": "Jambaló (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "404",
            "nombre": "La Sierra",
            "ciudad_departamento": "La Sierra (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "405",
            "nombre": "La Vega",
            "ciudad_departamento": "La Vega (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "406",
            "nombre": "López (Micay)",
            "ciudad_departamento": "López (Micay) (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "407",
            "nombre": "Mercaderes",
            "ciudad_departamento": "Mercaderes (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "408",
            "nombre": "Miranda",
            "ciudad_departamento": "Miranda (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "409",
            "nombre": "Morales",
            "ciudad_departamento": "Morales (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "410",
            "nombre": "Padilla",
            "ciudad_departamento": "Padilla (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "411",
            "nombre": "Patía (El Bordo)",
            "ciudad_departamento": "Patía (El Bordo) (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "412",
            "nombre": "Piamonte",
            "ciudad_departamento": "Piamonte (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "413",
            "nombre": "Piendamó",
            "ciudad_departamento": "Piendamó (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "414",
            "nombre": "Popayán",
            "ciudad_departamento": "Popayán (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "415",
            "nombre": "Puerto Tejada",
            "ciudad_departamento": "Puerto Tejada (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "416",
            "nombre": "Puracé (Coconuco)",
            "ciudad_departamento": "Puracé (Coconuco) (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "417",
            "nombre": "Páez (Belalcazar)",
            "ciudad_departamento": "Páez (Belalcazar) (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "418",
            "nombre": "Rosas",
            "ciudad_departamento": "Rosas (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "419",
            "nombre": "San Sebastián",
            "ciudad_departamento": "San Sebastián (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "420",
            "nombre": "Santa Rosa",
            "ciudad_departamento": "Santa Rosa (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "421",
            "nombre": "Santander de Quilichao",
            "ciudad_departamento": "Santander de Quilichao (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "422",
            "nombre": "Silvia",
            "ciudad_departamento": "Silvia (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "423",
            "nombre": "Sotara (Paispamba)",
            "ciudad_departamento": "Sotara (Paispamba) (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "424",
            "nombre": "Sucre",
            "ciudad_departamento": "Sucre (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "425",
            "nombre": "Suárez",
            "ciudad_departamento": "Suárez (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "426",
            "nombre": "Timbiquí",
            "ciudad_departamento": "Timbiquí (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "427",
            "nombre": "Timbío",
            "ciudad_departamento": "Timbío (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "428",
            "nombre": "Toribío",
            "ciudad_departamento": "Toribío (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "429",
            "nombre": "Totoró",
            "ciudad_departamento": "Totoró (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "430",
            "nombre": "Villa Rica",
            "ciudad_departamento": "Villa Rica (Cauca)",
            "indicativo": "602",
            "departamento": "Cauca"
        },
        {
            "id": "431",
            "nombre": "Aguachica",
            "ciudad_departamento": "Aguachica (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "432",
            "nombre": "Agustín Codazzi",
            "ciudad_departamento": "Agustín Codazzi (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "433",
            "nombre": "Astrea",
            "ciudad_departamento": "Astrea (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "434",
            "nombre": "Becerríl",
            "ciudad_departamento": "Becerríl (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "435",
            "nombre": "Bosconia",
            "ciudad_departamento": "Bosconia (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "436",
            "nombre": "Chimichagua",
            "ciudad_departamento": "Chimichagua (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "437",
            "nombre": "Chiriguaná",
            "ciudad_departamento": "Chiriguaná (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "438",
            "nombre": "Curumaní",
            "ciudad_departamento": "Curumaní (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "439",
            "nombre": "El Copey",
            "ciudad_departamento": "El Copey (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "440",
            "nombre": "El Paso",
            "ciudad_departamento": "El Paso (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "441",
            "nombre": "Gamarra",
            "ciudad_departamento": "Gamarra (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "442",
            "nombre": "Gonzalez",
            "ciudad_departamento": "Gonzalez (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "443",
            "nombre": "La Gloria",
            "ciudad_departamento": "La Gloria (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "444",
            "nombre": "La Jagua de Ibirico",
            "ciudad_departamento": "La Jagua de Ibirico (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "445",
            "nombre": "La Paz (Robles)",
            "ciudad_departamento": "La Paz (Robles) (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "446",
            "nombre": "Manaure Balcón del Cesar",
            "ciudad_departamento": "Manaure Balcón del Cesar (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "447",
            "nombre": "Pailitas",
            "ciudad_departamento": "Pailitas (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "448",
            "nombre": "Pelaya",
            "ciudad_departamento": "Pelaya (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "449",
            "nombre": "Pueblo Bello",
            "ciudad_departamento": "Pueblo Bello (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "450",
            "nombre": "Río de oro",
            "ciudad_departamento": "Río de oro (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "451",
            "nombre": "San Alberto",
            "ciudad_departamento": "San Alberto (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "452",
            "nombre": "San Diego",
            "ciudad_departamento": "San Diego (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "453",
            "nombre": "San Martín",
            "ciudad_departamento": "San Martín (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "454",
            "nombre": "Tamalameque",
            "ciudad_departamento": "Tamalameque (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "455",
            "nombre": "Valledupar",
            "ciudad_departamento": "Valledupar (Cesar)",
            "indicativo": "605",
            "departamento": "Cesar"
        },
        {
            "id": "456",
            "nombre": "Acandí",
            "ciudad_departamento": "Acandí (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "457",
            "nombre": "Alto Baudó (Pie de Pato)",
            "ciudad_departamento": "Alto Baudó (Pie de Pato) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "458",
            "nombre": "Atrato (Yuto)",
            "ciudad_departamento": "Atrato (Yuto) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "459",
            "nombre": "Bagadó",
            "ciudad_departamento": "Bagadó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "460",
            "nombre": "Bahía Solano (Mútis)",
            "ciudad_departamento": "Bahía Solano (Mútis) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "461",
            "nombre": "Bajo Baudó (Pizarro)",
            "ciudad_departamento": "Bajo Baudó (Pizarro) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "462",
            "nombre": "Belén de Bajirá",
            "ciudad_departamento": "Belén de Bajirá (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "463",
            "nombre": "Bojayá (Bellavista)",
            "ciudad_departamento": "Bojayá (Bellavista) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "464",
            "nombre": "Cantón de San Pablo",
            "ciudad_departamento": "Cantón de San Pablo (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "465",
            "nombre": "Carmen del Darién (CURBARADÓ)",
            "ciudad_departamento": "Carmen del Darién (CURBARADÓ) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "466",
            "nombre": "Condoto",
            "ciudad_departamento": "Condoto (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "467",
            "nombre": "Cértegui",
            "ciudad_departamento": "Cértegui (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "468",
            "nombre": "El Carmen de Atrato",
            "ciudad_departamento": "El Carmen de Atrato (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "469",
            "nombre": "Istmina",
            "ciudad_departamento": "Istmina (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "470",
            "nombre": "Juradó",
            "ciudad_departamento": "Juradó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "471",
            "nombre": "Lloró",
            "ciudad_departamento": "Lloró (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "472",
            "nombre": "Medio Atrato",
            "ciudad_departamento": "Medio Atrato (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "473",
            "nombre": "Medio Baudó",
            "ciudad_departamento": "Medio Baudó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "474",
            "nombre": "Medio San Juan (ANDAGOYA)",
            "ciudad_departamento": "Medio San Juan (ANDAGOYA) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "475",
            "nombre": "Novita",
            "ciudad_departamento": "Novita (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "476",
            "nombre": "Nuquí",
            "ciudad_departamento": "Nuquí (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "477",
            "nombre": "Quibdó",
            "ciudad_departamento": "Quibdó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "478",
            "nombre": "Río Iró",
            "ciudad_departamento": "Río Iró (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "479",
            "nombre": "Río Quito",
            "ciudad_departamento": "Río Quito (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "480",
            "nombre": "Ríosucio",
            "ciudad_departamento": "Ríosucio (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "481",
            "nombre": "San José del Palmar",
            "ciudad_departamento": "San José del Palmar (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "482",
            "nombre": "Santa Genoveva de Docorodó",
            "ciudad_departamento": "Santa Genoveva de Docorodó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "483",
            "nombre": "Sipí",
            "ciudad_departamento": "Sipí (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "484",
            "nombre": "Tadó",
            "ciudad_departamento": "Tadó (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "485",
            "nombre": "Unguía",
            "ciudad_departamento": "Unguía (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "486",
            "nombre": "Unión Panamericana (ÁNIMAS)",
            "ciudad_departamento": "Unión Panamericana (ÁNIMAS) (Chocó)",
            "indicativo": "604",
            "departamento": "Chocó"
        },
        {
            "id": "487",
            "nombre": "Ayapel",
            "ciudad_departamento": "Ayapel (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "488",
            "nombre": "Buenavista",
            "ciudad_departamento": "Buenavista (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "489",
            "nombre": "Canalete",
            "ciudad_departamento": "Canalete (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "490",
            "nombre": "Cereté",
            "ciudad_departamento": "Cereté (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "491",
            "nombre": "Chimá",
            "ciudad_departamento": "Chimá (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "492",
            "nombre": "Chinú",
            "ciudad_departamento": "Chinú (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "493",
            "nombre": "Ciénaga de Oro",
            "ciudad_departamento": "Ciénaga de Oro (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "494",
            "nombre": "Cotorra",
            "ciudad_departamento": "Cotorra (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "495",
            "nombre": "La Apartada y La Frontera",
            "ciudad_departamento": "La Apartada y La Frontera (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "496",
            "nombre": "Lorica",
            "ciudad_departamento": "Lorica (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "497",
            "nombre": "Los Córdobas",
            "ciudad_departamento": "Los Córdobas (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "498",
            "nombre": "Momil",
            "ciudad_departamento": "Momil (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "499",
            "nombre": "Montelíbano",
            "ciudad_departamento": "Montelíbano (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "500",
            "nombre": "Monteria",
            "ciudad_departamento": "Monteria (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "501",
            "nombre": "Moñitos",
            "ciudad_departamento": "Moñitos (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "502",
            "nombre": "Planeta Rica",
            "ciudad_departamento": "Planeta Rica (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "503",
            "nombre": "Pueblo Nuevo",
            "ciudad_departamento": "Pueblo Nuevo (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "504",
            "nombre": "Puerto Escondido",
            "ciudad_departamento": "Puerto Escondido (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "505",
            "nombre": "Puerto Libertador",
            "ciudad_departamento": "Puerto Libertador (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "506",
            "nombre": "Purísima",
            "ciudad_departamento": "Purísima (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "507",
            "nombre": "Sahagún",
            "ciudad_departamento": "Sahagún (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "508",
            "nombre": "San Andrés Sotavento",
            "ciudad_departamento": "San Andrés Sotavento (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "509",
            "nombre": "San Antero",
            "ciudad_departamento": "San Antero (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "510",
            "nombre": "San Bernardo del Viento",
            "ciudad_departamento": "San Bernardo del Viento (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "511",
            "nombre": "San Carlos",
            "ciudad_departamento": "San Carlos (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "512",
            "nombre": "San José de Uré",
            "ciudad_departamento": "San José de Uré (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "513",
            "nombre": "San Pelayo",
            "ciudad_departamento": "San Pelayo (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "514",
            "nombre": "Tierralta",
            "ciudad_departamento": "Tierralta (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "515",
            "nombre": "Tuchín",
            "ciudad_departamento": "Tuchín (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "516",
            "nombre": "Valencia",
            "ciudad_departamento": "Valencia (Córdoba)",
            "indicativo": "604",
            "departamento": "Córdoba"
        },
        {
            "id": "517",
            "nombre": "Agua de Dios",
            "ciudad_departamento": "Agua de Dios (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "518",
            "nombre": "Albán",
            "ciudad_departamento": "Albán (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "519",
            "nombre": "Anapoima",
            "ciudad_departamento": "Anapoima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "520",
            "nombre": "Anolaima",
            "ciudad_departamento": "Anolaima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "521",
            "nombre": "Apulo",
            "ciudad_departamento": "Apulo (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "522",
            "nombre": "Arbeláez",
            "ciudad_departamento": "Arbeláez (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "523",
            "nombre": "Beltrán",
            "ciudad_departamento": "Beltrán (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "524",
            "nombre": "Bituima",
            "ciudad_departamento": "Bituima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "525",
            "nombre": "Bogotá D.C.",
            "ciudad_departamento": "Bogotá D.C. (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "526",
            "nombre": "Bojacá",
            "ciudad_departamento": "Bojacá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "527",
            "nombre": "Cabrera",
            "ciudad_departamento": "Cabrera (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "528",
            "nombre": "Cachipay",
            "ciudad_departamento": "Cachipay (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "529",
            "nombre": "Cajicá",
            "ciudad_departamento": "Cajicá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "530",
            "nombre": "Caparrapí",
            "ciudad_departamento": "Caparrapí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "531",
            "nombre": "Carmen de Carupa",
            "ciudad_departamento": "Carmen de Carupa (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "532",
            "nombre": "Chaguaní",
            "ciudad_departamento": "Chaguaní (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "533",
            "nombre": "Chipaque",
            "ciudad_departamento": "Chipaque (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "534",
            "nombre": "Choachí",
            "ciudad_departamento": "Choachí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "535",
            "nombre": "Chocontá",
            "ciudad_departamento": "Chocontá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "536",
            "nombre": "Chía",
            "ciudad_departamento": "Chía (Cundinamarca)",
            "indicativo": "1",
            "departamento": "Cundinamarca"
        },
        {
            "id": "537",
            "nombre": "Cogua",
            "ciudad_departamento": "Cogua (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "538",
            "nombre": "Cota",
            "ciudad_departamento": "Cota (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "539",
            "nombre": "Cucunubá",
            "ciudad_departamento": "Cucunubá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "540",
            "nombre": "Cáqueza",
            "ciudad_departamento": "Cáqueza (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "541",
            "nombre": "El Colegio",
            "ciudad_departamento": "El Colegio (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "542",
            "nombre": "El Peñón",
            "ciudad_departamento": "El Peñón (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "543",
            "nombre": "El Rosal",
            "ciudad_departamento": "El Rosal (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "544",
            "nombre": "Facatativá",
            "ciudad_departamento": "Facatativá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "545",
            "nombre": "Fosca",
            "ciudad_departamento": "Fosca (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "546",
            "nombre": "Funza",
            "ciudad_departamento": "Funza (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "547",
            "nombre": "Fusagasugá",
            "ciudad_departamento": "Fusagasugá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "548",
            "nombre": "Fómeque",
            "ciudad_departamento": "Fómeque (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "549",
            "nombre": "Fúquene",
            "ciudad_departamento": "Fúquene (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "550",
            "nombre": "Gachalá",
            "ciudad_departamento": "Gachalá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "551",
            "nombre": "Gachancipá",
            "ciudad_departamento": "Gachancipá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "552",
            "nombre": "Gachetá",
            "ciudad_departamento": "Gachetá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "553",
            "nombre": "Gama",
            "ciudad_departamento": "Gama (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "554",
            "nombre": "Girardot",
            "ciudad_departamento": "Girardot (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "555",
            "nombre": "Granada",
            "ciudad_departamento": "Granada (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "556",
            "nombre": "Guachetá",
            "ciudad_departamento": "Guachetá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "557",
            "nombre": "Guaduas",
            "ciudad_departamento": "Guaduas (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "558",
            "nombre": "Guasca",
            "ciudad_departamento": "Guasca (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "559",
            "nombre": "Guataquí",
            "ciudad_departamento": "Guataquí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "560",
            "nombre": "Guatavita",
            "ciudad_departamento": "Guatavita (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "561",
            "nombre": "Guayabal de Siquima",
            "ciudad_departamento": "Guayabal de Siquima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "562",
            "nombre": "Guayabetal",
            "ciudad_departamento": "Guayabetal (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "563",
            "nombre": "Gutiérrez",
            "ciudad_departamento": "Gutiérrez (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "564",
            "nombre": "Jerusalén",
            "ciudad_departamento": "Jerusalén (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "565",
            "nombre": "Junín",
            "ciudad_departamento": "Junín (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "566",
            "nombre": "La Calera",
            "ciudad_departamento": "La Calera (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "567",
            "nombre": "La Mesa",
            "ciudad_departamento": "La Mesa (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "568",
            "nombre": "La Palma",
            "ciudad_departamento": "La Palma (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "569",
            "nombre": "La Peña",
            "ciudad_departamento": "La Peña (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "570",
            "nombre": "La Vega",
            "ciudad_departamento": "La Vega (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "571",
            "nombre": "Lenguazaque",
            "ciudad_departamento": "Lenguazaque (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "572",
            "nombre": "Machetá",
            "ciudad_departamento": "Machetá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "573",
            "nombre": "Madrid",
            "ciudad_departamento": "Madrid (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "574",
            "nombre": "Manta",
            "ciudad_departamento": "Manta (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "575",
            "nombre": "Medina",
            "ciudad_departamento": "Medina (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "576",
            "nombre": "Mosquera",
            "ciudad_departamento": "Mosquera (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "577",
            "nombre": "Nariño",
            "ciudad_departamento": "Nariño (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "578",
            "nombre": "Nemocón",
            "ciudad_departamento": "Nemocón (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "579",
            "nombre": "Nilo",
            "ciudad_departamento": "Nilo (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "580",
            "nombre": "Nimaima",
            "ciudad_departamento": "Nimaima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "581",
            "nombre": "Nocaima",
            "ciudad_departamento": "Nocaima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "582",
            "nombre": "Pacho",
            "ciudad_departamento": "Pacho (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "583",
            "nombre": "Paime",
            "ciudad_departamento": "Paime (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "584",
            "nombre": "Pandi",
            "ciudad_departamento": "Pandi (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "585",
            "nombre": "Paratebueno",
            "ciudad_departamento": "Paratebueno (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "586",
            "nombre": "Pasca",
            "ciudad_departamento": "Pasca (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "587",
            "nombre": "Puerto Salgar",
            "ciudad_departamento": "Puerto Salgar (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "588",
            "nombre": "Pulí",
            "ciudad_departamento": "Pulí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "589",
            "nombre": "Quebradanegra",
            "ciudad_departamento": "Quebradanegra (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "590",
            "nombre": "Quetame",
            "ciudad_departamento": "Quetame (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "591",
            "nombre": "Quipile",
            "ciudad_departamento": "Quipile (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "592",
            "nombre": "Ricaurte",
            "ciudad_departamento": "Ricaurte (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "593",
            "nombre": "San Antonio de Tequendama",
            "ciudad_departamento": "San Antonio de Tequendama (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "594",
            "nombre": "San Bernardo",
            "ciudad_departamento": "San Bernardo (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "595",
            "nombre": "San Cayetano",
            "ciudad_departamento": "San Cayetano (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "596",
            "nombre": "San Francisco",
            "ciudad_departamento": "San Francisco (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "597",
            "nombre": "San Juan de Río Seco",
            "ciudad_departamento": "San Juan de Río Seco (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "598",
            "nombre": "Sasaima",
            "ciudad_departamento": "Sasaima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "599",
            "nombre": "Sesquilé",
            "ciudad_departamento": "Sesquilé (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "600",
            "nombre": "Sibaté",
            "ciudad_departamento": "Sibaté (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "601",
            "nombre": "Silvania",
            "ciudad_departamento": "Silvania (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "602",
            "nombre": "Simijaca",
            "ciudad_departamento": "Simijaca (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "603",
            "nombre": "Soacha",
            "ciudad_departamento": "Soacha (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "604",
            "nombre": "Sopó",
            "ciudad_departamento": "Sopó (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "605",
            "nombre": "Subachoque",
            "ciudad_departamento": "Subachoque (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "606",
            "nombre": "Suesca",
            "ciudad_departamento": "Suesca (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "607",
            "nombre": "Supatá",
            "ciudad_departamento": "Supatá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "608",
            "nombre": "Susa",
            "ciudad_departamento": "Susa (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "609",
            "nombre": "Sutatausa",
            "ciudad_departamento": "Sutatausa (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "610",
            "nombre": "Tabio",
            "ciudad_departamento": "Tabio (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "611",
            "nombre": "Tausa",
            "ciudad_departamento": "Tausa (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "612",
            "nombre": "Tena",
            "ciudad_departamento": "Tena (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "613",
            "nombre": "Tenjo",
            "ciudad_departamento": "Tenjo (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "614",
            "nombre": "Tibacuy",
            "ciudad_departamento": "Tibacuy (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "615",
            "nombre": "Tibirita",
            "ciudad_departamento": "Tibirita (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "616",
            "nombre": "Tocaima",
            "ciudad_departamento": "Tocaima (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "617",
            "nombre": "Tocancipá",
            "ciudad_departamento": "Tocancipá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "618",
            "nombre": "Topaipí",
            "ciudad_departamento": "Topaipí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "619",
            "nombre": "Ubalá",
            "ciudad_departamento": "Ubalá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "620",
            "nombre": "Ubaque",
            "ciudad_departamento": "Ubaque (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "621",
            "nombre": "Ubaté",
            "ciudad_departamento": "Ubaté (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "622",
            "nombre": "Une",
            "ciudad_departamento": "Une (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "623",
            "nombre": "Venecia (Ospina Pérez)",
            "ciudad_departamento": "Venecia (Ospina Pérez) (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "624",
            "nombre": "Vergara",
            "ciudad_departamento": "Vergara (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "625",
            "nombre": "Viani",
            "ciudad_departamento": "Viani (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "626",
            "nombre": "Villagómez",
            "ciudad_departamento": "Villagómez (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "627",
            "nombre": "Villapinzón",
            "ciudad_departamento": "Villapinzón (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "628",
            "nombre": "Villeta",
            "ciudad_departamento": "Villeta (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "629",
            "nombre": "Viotá",
            "ciudad_departamento": "Viotá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "630",
            "nombre": "Yacopí",
            "ciudad_departamento": "Yacopí (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "631",
            "nombre": "Zipacón",
            "ciudad_departamento": "Zipacón (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "632",
            "nombre": "Zipaquirá",
            "ciudad_departamento": "Zipaquirá (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "633",
            "nombre": "Útica",
            "ciudad_departamento": "Útica (Cundinamarca)",
            "indicativo": "601",
            "departamento": "Cundinamarca"
        },
        {
            "id": "634",
            "nombre": "Inírida",
            "ciudad_departamento": "Inírida (Güainia)",
            "indicativo": "608",
            "departamento": "Güainia"
        },
        {
            "id": "635",
            "nombre": "Calamar",
            "ciudad_departamento": "Calamar (Guaviare)",
            "indicativo": "608",
            "departamento": "Guaviare"
        },
        {
            "id": "636",
            "nombre": "El Retorno",
            "ciudad_departamento": "El Retorno (Guaviare)",
            "indicativo": "608",
            "departamento": "Guaviare"
        },
        {
            "id": "637",
            "nombre": "Miraflores",
            "ciudad_departamento": "Miraflores (Guaviare)",
            "indicativo": "608",
            "departamento": "Guaviare"
        },
        {
            "id": "638",
            "nombre": "San José del Guaviare",
            "ciudad_departamento": "San José del Guaviare (Guaviare)",
            "indicativo": "608",
            "departamento": "Guaviare"
        },
        {
            "id": "639",
            "nombre": "Acevedo",
            "ciudad_departamento": "Acevedo (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "640",
            "nombre": "Agrado",
            "ciudad_departamento": "Agrado (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "641",
            "nombre": "Aipe",
            "ciudad_departamento": "Aipe (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "642",
            "nombre": "Algeciras",
            "ciudad_departamento": "Algeciras (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "643",
            "nombre": "Altamira",
            "ciudad_departamento": "Altamira (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "644",
            "nombre": "Baraya",
            "ciudad_departamento": "Baraya (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "645",
            "nombre": "Campoalegre",
            "ciudad_departamento": "Campoalegre (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "646",
            "nombre": "Colombia",
            "ciudad_departamento": "Colombia (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "647",
            "nombre": "Elías",
            "ciudad_departamento": "Elías (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "648",
            "nombre": "Garzón",
            "ciudad_departamento": "Garzón (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "649",
            "nombre": "Gigante",
            "ciudad_departamento": "Gigante (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "650",
            "nombre": "Guadalupe",
            "ciudad_departamento": "Guadalupe (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "651",
            "nombre": "Hobo",
            "ciudad_departamento": "Hobo (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "652",
            "nombre": "Isnos",
            "ciudad_departamento": "Isnos (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "653",
            "nombre": "La Argentina",
            "ciudad_departamento": "La Argentina (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "654",
            "nombre": "La Plata",
            "ciudad_departamento": "La Plata (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "655",
            "nombre": "Neiva",
            "ciudad_departamento": "Neiva (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "656",
            "nombre": "Nátaga",
            "ciudad_departamento": "Nátaga (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "657",
            "nombre": "Oporapa",
            "ciudad_departamento": "Oporapa (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "658",
            "nombre": "Paicol",
            "ciudad_departamento": "Paicol (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "659",
            "nombre": "Palermo",
            "ciudad_departamento": "Palermo (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "660",
            "nombre": "Palestina",
            "ciudad_departamento": "Palestina (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "661",
            "nombre": "Pital",
            "ciudad_departamento": "Pital (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "662",
            "nombre": "Pitalito",
            "ciudad_departamento": "Pitalito (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "663",
            "nombre": "Rivera",
            "ciudad_departamento": "Rivera (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "664",
            "nombre": "Saladoblanco",
            "ciudad_departamento": "Saladoblanco (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "665",
            "nombre": "San Agustín",
            "ciudad_departamento": "San Agustín (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "666",
            "nombre": "Santa María",
            "ciudad_departamento": "Santa María (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "667",
            "nombre": "Suaza",
            "ciudad_departamento": "Suaza (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "668",
            "nombre": "Tarqui",
            "ciudad_departamento": "Tarqui (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "669",
            "nombre": "Tello",
            "ciudad_departamento": "Tello (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "670",
            "nombre": "Teruel",
            "ciudad_departamento": "Teruel (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "671",
            "nombre": "Tesalia",
            "ciudad_departamento": "Tesalia (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "672",
            "nombre": "Timaná",
            "ciudad_departamento": "Timaná (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "673",
            "nombre": "Villavieja",
            "ciudad_departamento": "Villavieja (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "674",
            "nombre": "Yaguará",
            "ciudad_departamento": "Yaguará (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "675",
            "nombre": "Íquira",
            "ciudad_departamento": "Íquira (Huila)",
            "indicativo": "608",
            "departamento": "Huila"
        },
        {
            "id": "676",
            "nombre": "Albania",
            "ciudad_departamento": "Albania (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "677",
            "nombre": "Barrancas",
            "ciudad_departamento": "Barrancas (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "678",
            "nombre": "Dibulla",
            "ciudad_departamento": "Dibulla (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "679",
            "nombre": "Distracción",
            "ciudad_departamento": "Distracción (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "680",
            "nombre": "El Molino",
            "ciudad_departamento": "El Molino (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "681",
            "nombre": "Fonseca",
            "ciudad_departamento": "Fonseca (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "682",
            "nombre": "Hatonuevo",
            "ciudad_departamento": "Hatonuevo (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "683",
            "nombre": "La Jagua del Pilar",
            "ciudad_departamento": "La Jagua del Pilar (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "684",
            "nombre": "Maicao",
            "ciudad_departamento": "Maicao (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "685",
            "nombre": "Manaure",
            "ciudad_departamento": "Manaure (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "686",
            "nombre": "Riohacha",
            "ciudad_departamento": "Riohacha (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "687",
            "nombre": "San Juan del Cesar",
            "ciudad_departamento": "San Juan del Cesar (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "688",
            "nombre": "Uribia",
            "ciudad_departamento": "Uribia (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "689",
            "nombre": "Urumita",
            "ciudad_departamento": "Urumita (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "690",
            "nombre": "Villanueva",
            "ciudad_departamento": "Villanueva (La Guajira)",
            "indicativo": "605",
            "departamento": "La Guajira"
        },
        {
            "id": "691",
            "nombre": "Algarrobo",
            "ciudad_departamento": "Algarrobo (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "692",
            "nombre": "Aracataca",
            "ciudad_departamento": "Aracataca (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "693",
            "nombre": "Ariguaní (El Difícil)",
            "ciudad_departamento": "Ariguaní (El Difícil) (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "694",
            "nombre": "Cerro San Antonio",
            "ciudad_departamento": "Cerro San Antonio (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "695",
            "nombre": "Chivolo",
            "ciudad_departamento": "Chivolo (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "696",
            "nombre": "Ciénaga",
            "ciudad_departamento": "Ciénaga (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "697",
            "nombre": "Concordia",
            "ciudad_departamento": "Concordia (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "698",
            "nombre": "El Banco",
            "ciudad_departamento": "El Banco (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "699",
            "nombre": "El Piñon",
            "ciudad_departamento": "El Piñon (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "700",
            "nombre": "El Retén",
            "ciudad_departamento": "El Retén (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "701",
            "nombre": "Fundación",
            "ciudad_departamento": "Fundación (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "702",
            "nombre": "Guamal",
            "ciudad_departamento": "Guamal (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "703",
            "nombre": "Nueva Granada",
            "ciudad_departamento": "Nueva Granada (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "704",
            "nombre": "Pedraza",
            "ciudad_departamento": "Pedraza (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "705",
            "nombre": "Pijiño",
            "ciudad_departamento": "Pijiño (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "706",
            "nombre": "Pivijay",
            "ciudad_departamento": "Pivijay (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "707",
            "nombre": "Plato",
            "ciudad_departamento": "Plato (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "708",
            "nombre": "Puebloviejo",
            "ciudad_departamento": "Puebloviejo (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "709",
            "nombre": "Remolino",
            "ciudad_departamento": "Remolino (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "710",
            "nombre": "Sabanas de San Angel (SAN ANGEL)",
            "ciudad_departamento": "Sabanas de San Angel (SAN ANGEL) (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "711",
            "nombre": "Salamina",
            "ciudad_departamento": "Salamina (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "712",
            "nombre": "San Sebastián de Buenavista",
            "ciudad_departamento": "San Sebastián de Buenavista (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "713",
            "nombre": "San Zenón",
            "ciudad_departamento": "San Zenón (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "714",
            "nombre": "Santa Ana",
            "ciudad_departamento": "Santa Ana (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "715",
            "nombre": "Santa Bárbara de Pinto",
            "ciudad_departamento": "Santa Bárbara de Pinto (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "716",
            "nombre": "Santa Marta",
            "ciudad_departamento": "Santa Marta (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "717",
            "nombre": "Sitionuevo",
            "ciudad_departamento": "Sitionuevo (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "718",
            "nombre": "Tenerife",
            "ciudad_departamento": "Tenerife (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "719",
            "nombre": "Zapayán (PUNTA DE PIEDRAS)",
            "ciudad_departamento": "Zapayán (PUNTA DE PIEDRAS) (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "720",
            "nombre": "Zona Bananera (PRADO - SEVILLA)",
            "ciudad_departamento": "Zona Bananera (PRADO - SEVILLA) (Magdalena)",
            "indicativo": "605",
            "departamento": "Magdalena"
        },
        {
            "id": "721",
            "nombre": "Acacías",
            "ciudad_departamento": "Acacías (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "722",
            "nombre": "Barranca de Upía",
            "ciudad_departamento": "Barranca de Upía (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "723",
            "nombre": "Cabuyaro",
            "ciudad_departamento": "Cabuyaro (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "724",
            "nombre": "Castilla la Nueva",
            "ciudad_departamento": "Castilla la Nueva (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "725",
            "nombre": "Cubarral",
            "ciudad_departamento": "Cubarral (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "726",
            "nombre": "Cumaral",
            "ciudad_departamento": "Cumaral (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "727",
            "nombre": "El Calvario",
            "ciudad_departamento": "El Calvario (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "728",
            "nombre": "El Castillo",
            "ciudad_departamento": "El Castillo (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "729",
            "nombre": "El Dorado",
            "ciudad_departamento": "El Dorado (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "730",
            "nombre": "Fuente de Oro",
            "ciudad_departamento": "Fuente de Oro (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "731",
            "nombre": "Granada",
            "ciudad_departamento": "Granada (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "732",
            "nombre": "Guamal",
            "ciudad_departamento": "Guamal (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "733",
            "nombre": "La Macarena",
            "ciudad_departamento": "La Macarena (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "734",
            "nombre": "Lejanías",
            "ciudad_departamento": "Lejanías (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "735",
            "nombre": "Mapiripan",
            "ciudad_departamento": "Mapiripan (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "736",
            "nombre": "Mesetas",
            "ciudad_departamento": "Mesetas (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "737",
            "nombre": "Puerto Concordia",
            "ciudad_departamento": "Puerto Concordia (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "738",
            "nombre": "Puerto Gaitán",
            "ciudad_departamento": "Puerto Gaitán (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "739",
            "nombre": "Puerto Lleras",
            "ciudad_departamento": "Puerto Lleras (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "740",
            "nombre": "Puerto López",
            "ciudad_departamento": "Puerto López (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "741",
            "nombre": "Puerto Rico",
            "ciudad_departamento": "Puerto Rico (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "742",
            "nombre": "Restrepo",
            "ciudad_departamento": "Restrepo (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "743",
            "nombre": "San Carlos de Guaroa",
            "ciudad_departamento": "San Carlos de Guaroa (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "744",
            "nombre": "San Juan de Arama",
            "ciudad_departamento": "San Juan de Arama (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "745",
            "nombre": "San Juanito",
            "ciudad_departamento": "San Juanito (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "746",
            "nombre": "San Martín",
            "ciudad_departamento": "San Martín (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "747",
            "nombre": "Uribe",
            "ciudad_departamento": "Uribe (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "748",
            "nombre": "Villavicencio",
            "ciudad_departamento": "Villavicencio (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "749",
            "nombre": "Vista Hermosa",
            "ciudad_departamento": "Vista Hermosa (Meta)",
            "indicativo": "608",
            "departamento": "Meta"
        },
        {
            "id": "750",
            "nombre": "Albán (San José)",
            "ciudad_departamento": "Albán (San José) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "751",
            "nombre": "Aldana",
            "ciudad_departamento": "Aldana (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "752",
            "nombre": "Ancuya",
            "ciudad_departamento": "Ancuya (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "753",
            "nombre": "Arboleda (Berruecos)",
            "ciudad_departamento": "Arboleda (Berruecos) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "754",
            "nombre": "Barbacoas",
            "ciudad_departamento": "Barbacoas (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "755",
            "nombre": "Belén",
            "ciudad_departamento": "Belén (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "756",
            "nombre": "Buesaco",
            "ciudad_departamento": "Buesaco (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "757",
            "nombre": "Chachaguí",
            "ciudad_departamento": "Chachaguí (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "758",
            "nombre": "Colón (Génova)",
            "ciudad_departamento": "Colón (Génova) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "759",
            "nombre": "Consaca",
            "ciudad_departamento": "Consaca (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "760",
            "nombre": "Contadero",
            "ciudad_departamento": "Contadero (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "761",
            "nombre": "Cuaspud (Carlosama)",
            "ciudad_departamento": "Cuaspud (Carlosama) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "762",
            "nombre": "Cumbal",
            "ciudad_departamento": "Cumbal (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "763",
            "nombre": "Cumbitara",
            "ciudad_departamento": "Cumbitara (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "764",
            "nombre": "Córdoba",
            "ciudad_departamento": "Córdoba (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "765",
            "nombre": "El Charco",
            "ciudad_departamento": "El Charco (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "766",
            "nombre": "El Peñol",
            "ciudad_departamento": "El Peñol (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "767",
            "nombre": "El Rosario",
            "ciudad_departamento": "El Rosario (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "768",
            "nombre": "El Tablón de Gómez",
            "ciudad_departamento": "El Tablón de Gómez (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "769",
            "nombre": "El Tambo",
            "ciudad_departamento": "El Tambo (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "770",
            "nombre": "Francisco Pizarro",
            "ciudad_departamento": "Francisco Pizarro (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "771",
            "nombre": "Funes",
            "ciudad_departamento": "Funes (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "772",
            "nombre": "Guachavés",
            "ciudad_departamento": "Guachavés (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "773",
            "nombre": "Guachucal",
            "ciudad_departamento": "Guachucal (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "774",
            "nombre": "Guaitarilla",
            "ciudad_departamento": "Guaitarilla (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "775",
            "nombre": "Gualmatán",
            "ciudad_departamento": "Gualmatán (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "776",
            "nombre": "Iles",
            "ciudad_departamento": "Iles (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "777",
            "nombre": "Imúes",
            "ciudad_departamento": "Imúes (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "778",
            "nombre": "Ipiales",
            "ciudad_departamento": "Ipiales (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "779",
            "nombre": "La Cruz",
            "ciudad_departamento": "La Cruz (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "780",
            "nombre": "La Florida",
            "ciudad_departamento": "La Florida (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "781",
            "nombre": "La Llanada",
            "ciudad_departamento": "La Llanada (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "782",
            "nombre": "La Tola",
            "ciudad_departamento": "La Tola (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "783",
            "nombre": "La Unión",
            "ciudad_departamento": "La Unión (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "784",
            "nombre": "Leiva",
            "ciudad_departamento": "Leiva (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "785",
            "nombre": "Linares",
            "ciudad_departamento": "Linares (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "786",
            "nombre": "Magüi (Payán)",
            "ciudad_departamento": "Magüi (Payán) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "787",
            "nombre": "Mallama (Piedrancha)",
            "ciudad_departamento": "Mallama (Piedrancha) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "788",
            "nombre": "Mosquera",
            "ciudad_departamento": "Mosquera (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "789",
            "nombre": "Nariño",
            "ciudad_departamento": "Nariño (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "790",
            "nombre": "Olaya Herrera",
            "ciudad_departamento": "Olaya Herrera (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "791",
            "nombre": "Ospina",
            "ciudad_departamento": "Ospina (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "792",
            "nombre": "Policarpa",
            "ciudad_departamento": "Policarpa (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "793",
            "nombre": "Potosí",
            "ciudad_departamento": "Potosí (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "794",
            "nombre": "Providencia",
            "ciudad_departamento": "Providencia (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "795",
            "nombre": "Puerres",
            "ciudad_departamento": "Puerres (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "796",
            "nombre": "Pupiales",
            "ciudad_departamento": "Pupiales (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "797",
            "nombre": "Ricaurte",
            "ciudad_departamento": "Ricaurte (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "798",
            "nombre": "Roberto Payán (San José)",
            "ciudad_departamento": "Roberto Payán (San José) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "799",
            "nombre": "Samaniego",
            "ciudad_departamento": "Samaniego (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "800",
            "nombre": "San Bernardo",
            "ciudad_departamento": "San Bernardo (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "801",
            "nombre": "San Juan de Pasto",
            "ciudad_departamento": "San Juan de Pasto (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "802",
            "nombre": "San Lorenzo",
            "ciudad_departamento": "San Lorenzo (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "803",
            "nombre": "San Pablo",
            "ciudad_departamento": "San Pablo (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "804",
            "nombre": "San Pedro de Cartago",
            "ciudad_departamento": "San Pedro de Cartago (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "805",
            "nombre": "Sandoná",
            "ciudad_departamento": "Sandoná (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "806",
            "nombre": "Santa Bárbara (Iscuandé)",
            "ciudad_departamento": "Santa Bárbara (Iscuandé) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "807",
            "nombre": "Sapuyes",
            "ciudad_departamento": "Sapuyes (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "808",
            "nombre": "Sotomayor (Los Andes)",
            "ciudad_departamento": "Sotomayor (Los Andes) (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "809",
            "nombre": "Taminango",
            "ciudad_departamento": "Taminango (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "810",
            "nombre": "Tangua",
            "ciudad_departamento": "Tangua (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "811",
            "nombre": "Tumaco",
            "ciudad_departamento": "Tumaco (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "812",
            "nombre": "Túquerres",
            "ciudad_departamento": "Túquerres (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "813",
            "nombre": "Yacuanquer",
            "ciudad_departamento": "Yacuanquer (Nariño)",
            "indicativo": "602",
            "departamento": "Nariño"
        },
        {
            "id": "814",
            "nombre": "Arboledas",
            "ciudad_departamento": "Arboledas (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "815",
            "nombre": "Bochalema",
            "ciudad_departamento": "Bochalema (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "816",
            "nombre": "Bucarasica",
            "ciudad_departamento": "Bucarasica (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "817",
            "nombre": "Chinácota",
            "ciudad_departamento": "Chinácota (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "818",
            "nombre": "Chitagá",
            "ciudad_departamento": "Chitagá (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "819",
            "nombre": "Convención",
            "ciudad_departamento": "Convención (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "820",
            "nombre": "Cucutilla",
            "ciudad_departamento": "Cucutilla (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "821",
            "nombre": "Cáchira",
            "ciudad_departamento": "Cáchira (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "822",
            "nombre": "Cácota",
            "ciudad_departamento": "Cácota (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "823",
            "nombre": "Cúcuta",
            "ciudad_departamento": "Cúcuta (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "824",
            "nombre": "Durania",
            "ciudad_departamento": "Durania (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "825",
            "nombre": "El Carmen",
            "ciudad_departamento": "El Carmen (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "826",
            "nombre": "El Tarra",
            "ciudad_departamento": "El Tarra (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "827",
            "nombre": "El Zulia",
            "ciudad_departamento": "El Zulia (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "828",
            "nombre": "Gramalote",
            "ciudad_departamento": "Gramalote (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "829",
            "nombre": "Hacarí",
            "ciudad_departamento": "Hacarí (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "830",
            "nombre": "Herrán",
            "ciudad_departamento": "Herrán (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "831",
            "nombre": "La Esperanza",
            "ciudad_departamento": "La Esperanza (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "832",
            "nombre": "La Playa",
            "ciudad_departamento": "La Playa (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "833",
            "nombre": "Labateca",
            "ciudad_departamento": "Labateca (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "834",
            "nombre": "Los Patios",
            "ciudad_departamento": "Los Patios (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "835",
            "nombre": "Lourdes",
            "ciudad_departamento": "Lourdes (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "836",
            "nombre": "Mutiscua",
            "ciudad_departamento": "Mutiscua (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "837",
            "nombre": "Ocaña",
            "ciudad_departamento": "Ocaña (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "838",
            "nombre": "Pamplona",
            "ciudad_departamento": "Pamplona (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "839",
            "nombre": "Pamplonita",
            "ciudad_departamento": "Pamplonita (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "840",
            "nombre": "Puerto Santander",
            "ciudad_departamento": "Puerto Santander (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "841",
            "nombre": "Ragonvalia",
            "ciudad_departamento": "Ragonvalia (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "842",
            "nombre": "Salazar",
            "ciudad_departamento": "Salazar (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "843",
            "nombre": "San Calixto",
            "ciudad_departamento": "San Calixto (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "844",
            "nombre": "San Cayetano",
            "ciudad_departamento": "San Cayetano (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "845",
            "nombre": "Santiago",
            "ciudad_departamento": "Santiago (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "846",
            "nombre": "Sardinata",
            "ciudad_departamento": "Sardinata (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "847",
            "nombre": "Silos",
            "ciudad_departamento": "Silos (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "848",
            "nombre": "Teorama",
            "ciudad_departamento": "Teorama (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "849",
            "nombre": "Tibú",
            "ciudad_departamento": "Tibú (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "850",
            "nombre": "Toledo",
            "ciudad_departamento": "Toledo (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "851",
            "nombre": "Villa Caro",
            "ciudad_departamento": "Villa Caro (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "852",
            "nombre": "Villa del Rosario",
            "ciudad_departamento": "Villa del Rosario (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "853",
            "nombre": "Ábrego",
            "ciudad_departamento": "Ábrego (Norte de Santander)",
            "indicativo": "607",
            "departamento": "Norte de Santander"
        },
        {
            "id": "854",
            "nombre": "Colón",
            "ciudad_departamento": "Colón (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "855",
            "nombre": "Mocoa",
            "ciudad_departamento": "Mocoa (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "856",
            "nombre": "Orito",
            "ciudad_departamento": "Orito (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "857",
            "nombre": "Puerto Asís",
            "ciudad_departamento": "Puerto Asís (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "858",
            "nombre": "Puerto Caicedo",
            "ciudad_departamento": "Puerto Caicedo (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "859",
            "nombre": "Puerto Guzmán",
            "ciudad_departamento": "Puerto Guzmán (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "860",
            "nombre": "Puerto Leguízamo",
            "ciudad_departamento": "Puerto Leguízamo (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "861",
            "nombre": "San Francisco",
            "ciudad_departamento": "San Francisco (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "862",
            "nombre": "San Miguel",
            "ciudad_departamento": "San Miguel (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "863",
            "nombre": "Santiago",
            "ciudad_departamento": "Santiago (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "864",
            "nombre": "Sibundoy",
            "ciudad_departamento": "Sibundoy (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "865",
            "nombre": "Valle del Guamuez",
            "ciudad_departamento": "Valle del Guamuez (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "866",
            "nombre": "Villagarzón",
            "ciudad_departamento": "Villagarzón (Putumayo)",
            "indicativo": "608",
            "departamento": "Putumayo"
        },
        {
            "id": "867",
            "nombre": "Armenia",
            "ciudad_departamento": "Armenia (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "868",
            "nombre": "Buenavista",
            "ciudad_departamento": "Buenavista (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "869",
            "nombre": "Calarcá",
            "ciudad_departamento": "Calarcá (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "870",
            "nombre": "Circasia",
            "ciudad_departamento": "Circasia (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "871",
            "nombre": "Cordobá",
            "ciudad_departamento": "Cordobá (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "872",
            "nombre": "Filandia",
            "ciudad_departamento": "Filandia (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "873",
            "nombre": "Génova",
            "ciudad_departamento": "Génova (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "874",
            "nombre": "La Tebaida",
            "ciudad_departamento": "La Tebaida (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "875",
            "nombre": "Montenegro",
            "ciudad_departamento": "Montenegro (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "876",
            "nombre": "Pijao",
            "ciudad_departamento": "Pijao (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "877",
            "nombre": "Quimbaya",
            "ciudad_departamento": "Quimbaya (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "878",
            "nombre": "Salento",
            "ciudad_departamento": "Salento (Quindo)",
            "indicativo": "606",
            "departamento": "Quindo"
        },
        {
            "id": "879",
            "nombre": "Apía",
            "ciudad_departamento": "Apía (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "880",
            "nombre": "Balboa",
            "ciudad_departamento": "Balboa (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "881",
            "nombre": "Belén de Umbría",
            "ciudad_departamento": "Belén de Umbría (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "882",
            "nombre": "Dos Quebradas",
            "ciudad_departamento": "Dos Quebradas (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "883",
            "nombre": "Guática",
            "ciudad_departamento": "Guática (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "884",
            "nombre": "La Celia",
            "ciudad_departamento": "La Celia (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "885",
            "nombre": "La Virginia",
            "ciudad_departamento": "La Virginia (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "886",
            "nombre": "Marsella",
            "ciudad_departamento": "Marsella (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "887",
            "nombre": "Mistrató",
            "ciudad_departamento": "Mistrató (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "888",
            "nombre": "Pereira",
            "ciudad_departamento": "Pereira (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "889",
            "nombre": "Pueblo Rico",
            "ciudad_departamento": "Pueblo Rico (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "890",
            "nombre": "Quinchía",
            "ciudad_departamento": "Quinchía (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "891",
            "nombre": "Santa Rosa de Cabal",
            "ciudad_departamento": "Santa Rosa de Cabal (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "892",
            "nombre": "Santuario",
            "ciudad_departamento": "Santuario (Risaralda)",
            "indicativo": "606",
            "departamento": "Risaralda"
        },
        {
            "id": "893",
            "nombre": "Providencia",
            "ciudad_departamento": "Providencia (San Andrés y Providencia)",
            "indicativo": "608",
            "departamento": "San Andrés y Providencia"
        },
        {
            "id": "894",
            "nombre": "Aguada",
            "ciudad_departamento": "Aguada (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "895",
            "nombre": "Albania",
            "ciudad_departamento": "Albania (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "896",
            "nombre": "Aratoca",
            "ciudad_departamento": "Aratoca (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "897",
            "nombre": "Barbosa",
            "ciudad_departamento": "Barbosa (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "898",
            "nombre": "Barichara",
            "ciudad_departamento": "Barichara (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "899",
            "nombre": "Barrancabermeja",
            "ciudad_departamento": "Barrancabermeja (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "900",
            "nombre": "Betulia",
            "ciudad_departamento": "Betulia (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "901",
            "nombre": "Bolívar",
            "ciudad_departamento": "Bolívar (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "902",
            "nombre": "Bucaramanga",
            "ciudad_departamento": "Bucaramanga (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "903",
            "nombre": "Cabrera",
            "ciudad_departamento": "Cabrera (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "904",
            "nombre": "California",
            "ciudad_departamento": "California (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "905",
            "nombre": "Capitanejo",
            "ciudad_departamento": "Capitanejo (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "906",
            "nombre": "Carcasí",
            "ciudad_departamento": "Carcasí (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "907",
            "nombre": "Cepita",
            "ciudad_departamento": "Cepita (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "908",
            "nombre": "Cerrito",
            "ciudad_departamento": "Cerrito (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "909",
            "nombre": "Charalá",
            "ciudad_departamento": "Charalá (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "910",
            "nombre": "Charta",
            "ciudad_departamento": "Charta (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "911",
            "nombre": "Chima",
            "ciudad_departamento": "Chima (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "912",
            "nombre": "Chipatá",
            "ciudad_departamento": "Chipatá (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "913",
            "nombre": "Cimitarra",
            "ciudad_departamento": "Cimitarra (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "914",
            "nombre": "Concepción",
            "ciudad_departamento": "Concepción (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "915",
            "nombre": "Confines",
            "ciudad_departamento": "Confines (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "916",
            "nombre": "Contratación",
            "ciudad_departamento": "Contratación (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "917",
            "nombre": "Coromoro",
            "ciudad_departamento": "Coromoro (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "918",
            "nombre": "Curití",
            "ciudad_departamento": "Curití (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "919",
            "nombre": "El Carmen",
            "ciudad_departamento": "El Carmen (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "920",
            "nombre": "El Guacamayo",
            "ciudad_departamento": "El Guacamayo (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "921",
            "nombre": "El Peñon",
            "ciudad_departamento": "El Peñon (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "922",
            "nombre": "El Playón",
            "ciudad_departamento": "El Playón (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "923",
            "nombre": "Encino",
            "ciudad_departamento": "Encino (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "924",
            "nombre": "Enciso",
            "ciudad_departamento": "Enciso (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "925",
            "nombre": "Floridablanca",
            "ciudad_departamento": "Floridablanca (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "926",
            "nombre": "Florián",
            "ciudad_departamento": "Florián (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "927",
            "nombre": "Galán",
            "ciudad_departamento": "Galán (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "928",
            "nombre": "Girón",
            "ciudad_departamento": "Girón (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "929",
            "nombre": "Guaca",
            "ciudad_departamento": "Guaca (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "930",
            "nombre": "Guadalupe",
            "ciudad_departamento": "Guadalupe (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "931",
            "nombre": "Guapota",
            "ciudad_departamento": "Guapota (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "932",
            "nombre": "Guavatá",
            "ciudad_departamento": "Guavatá (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "933",
            "nombre": "Guepsa",
            "ciudad_departamento": "Guepsa (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "934",
            "nombre": "Gámbita",
            "ciudad_departamento": "Gámbita (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "935",
            "nombre": "Hato",
            "ciudad_departamento": "Hato (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "936",
            "nombre": "Jesús María",
            "ciudad_departamento": "Jesús María (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "937",
            "nombre": "Jordán",
            "ciudad_departamento": "Jordán (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "938",
            "nombre": "La Belleza",
            "ciudad_departamento": "La Belleza (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "939",
            "nombre": "La Paz",
            "ciudad_departamento": "La Paz (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "940",
            "nombre": "Landázuri",
            "ciudad_departamento": "Landázuri (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "941",
            "nombre": "Lebrija",
            "ciudad_departamento": "Lebrija (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "942",
            "nombre": "Los Santos",
            "ciudad_departamento": "Los Santos (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "943",
            "nombre": "Macaravita",
            "ciudad_departamento": "Macaravita (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "944",
            "nombre": "Matanza",
            "ciudad_departamento": "Matanza (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "945",
            "nombre": "Mogotes",
            "ciudad_departamento": "Mogotes (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "946",
            "nombre": "Molagavita",
            "ciudad_departamento": "Molagavita (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "947",
            "nombre": "Málaga",
            "ciudad_departamento": "Málaga (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "948",
            "nombre": "Ocamonte",
            "ciudad_departamento": "Ocamonte (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "949",
            "nombre": "Oiba",
            "ciudad_departamento": "Oiba (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "950",
            "nombre": "Onzaga",
            "ciudad_departamento": "Onzaga (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "951",
            "nombre": "Palmar",
            "ciudad_departamento": "Palmar (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "952",
            "nombre": "Palmas del Socorro",
            "ciudad_departamento": "Palmas del Socorro (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "953",
            "nombre": "Pie de Cuesta",
            "ciudad_departamento": "Pie de Cuesta (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "954",
            "nombre": "Pinchote",
            "ciudad_departamento": "Pinchote (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "955",
            "nombre": "Puente Nacional",
            "ciudad_departamento": "Puente Nacional (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "956",
            "nombre": "Puerto Parra",
            "ciudad_departamento": "Puerto Parra (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "957",
            "nombre": "Puerto Wilches",
            "ciudad_departamento": "Puerto Wilches (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "958",
            "nombre": "Páramo",
            "ciudad_departamento": "Páramo (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "959",
            "nombre": "Rio Negro",
            "ciudad_departamento": "Rio Negro (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "960",
            "nombre": "Sabana de Torres",
            "ciudad_departamento": "Sabana de Torres (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "961",
            "nombre": "San Andrés",
            "ciudad_departamento": "San Andrés (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "962",
            "nombre": "San Benito",
            "ciudad_departamento": "San Benito (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "963",
            "nombre": "San Gíl",
            "ciudad_departamento": "San Gíl (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "964",
            "nombre": "San Joaquín",
            "ciudad_departamento": "San Joaquín (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "965",
            "nombre": "San José de Miranda",
            "ciudad_departamento": "San José de Miranda (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "966",
            "nombre": "San Miguel",
            "ciudad_departamento": "San Miguel (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "967",
            "nombre": "San Vicente del Chucurí",
            "ciudad_departamento": "San Vicente del Chucurí (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "968",
            "nombre": "Santa Bárbara",
            "ciudad_departamento": "Santa Bárbara (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "969",
            "nombre": "Santa Helena del Opón",
            "ciudad_departamento": "Santa Helena del Opón (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "970",
            "nombre": "Simacota",
            "ciudad_departamento": "Simacota (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "971",
            "nombre": "Socorro",
            "ciudad_departamento": "Socorro (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "972",
            "nombre": "Suaita",
            "ciudad_departamento": "Suaita (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "973",
            "nombre": "Sucre",
            "ciudad_departamento": "Sucre (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "974",
            "nombre": "Suratá",
            "ciudad_departamento": "Suratá (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "975",
            "nombre": "Tona",
            "ciudad_departamento": "Tona (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "976",
            "nombre": "Valle de San José",
            "ciudad_departamento": "Valle de San José (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "977",
            "nombre": "Vetas",
            "ciudad_departamento": "Vetas (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "978",
            "nombre": "Villanueva",
            "ciudad_departamento": "Villanueva (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "979",
            "nombre": "Vélez",
            "ciudad_departamento": "Vélez (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "980",
            "nombre": "Zapatoca",
            "ciudad_departamento": "Zapatoca (Santander)",
            "indicativo": "607",
            "departamento": "Santander"
        },
        {
            "id": "981",
            "nombre": "Buenavista",
            "ciudad_departamento": "Buenavista (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "982",
            "nombre": "Caimito",
            "ciudad_departamento": "Caimito (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "983",
            "nombre": "Chalán",
            "ciudad_departamento": "Chalán (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "984",
            "nombre": "Colosó (Ricaurte)",
            "ciudad_departamento": "Colosó (Ricaurte) (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "985",
            "nombre": "Corozal",
            "ciudad_departamento": "Corozal (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "986",
            "nombre": "Coveñas",
            "ciudad_departamento": "Coveñas (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "987",
            "nombre": "El Roble",
            "ciudad_departamento": "El Roble (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "988",
            "nombre": "Galeras (Nueva Granada)",
            "ciudad_departamento": "Galeras (Nueva Granada) (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "989",
            "nombre": "Guaranda",
            "ciudad_departamento": "Guaranda (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "990",
            "nombre": "La Unión",
            "ciudad_departamento": "La Unión (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "991",
            "nombre": "Los Palmitos",
            "ciudad_departamento": "Los Palmitos (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "992",
            "nombre": "Majagual",
            "ciudad_departamento": "Majagual (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "993",
            "nombre": "Morroa",
            "ciudad_departamento": "Morroa (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "994",
            "nombre": "Ovejas",
            "ciudad_departamento": "Ovejas (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "995",
            "nombre": "Palmito",
            "ciudad_departamento": "Palmito (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "996",
            "nombre": "Sampués",
            "ciudad_departamento": "Sampués (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "997",
            "nombre": "San Benito Abad",
            "ciudad_departamento": "San Benito Abad (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "998",
            "nombre": "San Juan de Betulia",
            "ciudad_departamento": "San Juan de Betulia (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "999",
            "nombre": "San Marcos",
            "ciudad_departamento": "San Marcos (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1000",
            "nombre": "San Onofre",
            "ciudad_departamento": "San Onofre (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1001",
            "nombre": "San Pedro",
            "ciudad_departamento": "San Pedro (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1002",
            "nombre": "Sincelejo",
            "ciudad_departamento": "Sincelejo (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1003",
            "nombre": "Sincé",
            "ciudad_departamento": "Sincé (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1004",
            "nombre": "Sucre",
            "ciudad_departamento": "Sucre (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1005",
            "nombre": "Tolú",
            "ciudad_departamento": "Tolú (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1006",
            "nombre": "Tolú Viejo",
            "ciudad_departamento": "Tolú Viejo (Sucre)",
            "indicativo": "605",
            "departamento": "Sucre"
        },
        {
            "id": "1007",
            "nombre": "Alpujarra",
            "ciudad_departamento": "Alpujarra (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1008",
            "nombre": "Alvarado",
            "ciudad_departamento": "Alvarado (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1009",
            "nombre": "Ambalema",
            "ciudad_departamento": "Ambalema (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1010",
            "nombre": "Anzoátegui",
            "ciudad_departamento": "Anzoátegui (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1011",
            "nombre": "Armero (Guayabal)",
            "ciudad_departamento": "Armero (Guayabal) (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1012",
            "nombre": "Ataco",
            "ciudad_departamento": "Ataco (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1013",
            "nombre": "Cajamarca",
            "ciudad_departamento": "Cajamarca (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1014",
            "nombre": "Carmen de Apicalá",
            "ciudad_departamento": "Carmen de Apicalá (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1015",
            "nombre": "Casabianca",
            "ciudad_departamento": "Casabianca (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1016",
            "nombre": "Chaparral",
            "ciudad_departamento": "Chaparral (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1017",
            "nombre": "Coello",
            "ciudad_departamento": "Coello (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1018",
            "nombre": "Coyaima",
            "ciudad_departamento": "Coyaima (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1019",
            "nombre": "Cunday",
            "ciudad_departamento": "Cunday (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1020",
            "nombre": "Dolores",
            "ciudad_departamento": "Dolores (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1021",
            "nombre": "Espinal",
            "ciudad_departamento": "Espinal (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1022",
            "nombre": "Falan",
            "ciudad_departamento": "Falan (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1023",
            "nombre": "Flandes",
            "ciudad_departamento": "Flandes (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1024",
            "nombre": "Fresno",
            "ciudad_departamento": "Fresno (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1025",
            "nombre": "Guamo",
            "ciudad_departamento": "Guamo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1026",
            "nombre": "Herveo",
            "ciudad_departamento": "Herveo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1027",
            "nombre": "Honda",
            "ciudad_departamento": "Honda (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1028",
            "nombre": "Ibagué",
            "ciudad_departamento": "Ibagué (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1029",
            "nombre": "Icononzo",
            "ciudad_departamento": "Icononzo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1030",
            "nombre": "Lérida",
            "ciudad_departamento": "Lérida (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1031",
            "nombre": "Líbano",
            "ciudad_departamento": "Líbano (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1032",
            "nombre": "Mariquita",
            "ciudad_departamento": "Mariquita (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1033",
            "nombre": "Melgar",
            "ciudad_departamento": "Melgar (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1034",
            "nombre": "Murillo",
            "ciudad_departamento": "Murillo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1035",
            "nombre": "Natagaima",
            "ciudad_departamento": "Natagaima (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1036",
            "nombre": "Ortega",
            "ciudad_departamento": "Ortega (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1037",
            "nombre": "Palocabildo",
            "ciudad_departamento": "Palocabildo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1038",
            "nombre": "Piedras",
            "ciudad_departamento": "Piedras (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1039",
            "nombre": "Planadas",
            "ciudad_departamento": "Planadas (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1040",
            "nombre": "Prado",
            "ciudad_departamento": "Prado (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1041",
            "nombre": "Purificación",
            "ciudad_departamento": "Purificación (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1042",
            "nombre": "Rioblanco",
            "ciudad_departamento": "Rioblanco (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1043",
            "nombre": "Roncesvalles",
            "ciudad_departamento": "Roncesvalles (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1044",
            "nombre": "Rovira",
            "ciudad_departamento": "Rovira (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1045",
            "nombre": "Saldaña",
            "ciudad_departamento": "Saldaña (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1046",
            "nombre": "San Antonio",
            "ciudad_departamento": "San Antonio (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1047",
            "nombre": "San Luis",
            "ciudad_departamento": "San Luis (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1048",
            "nombre": "Santa Isabel",
            "ciudad_departamento": "Santa Isabel (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1049",
            "nombre": "Suárez",
            "ciudad_departamento": "Suárez (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1050",
            "nombre": "Valle de San Juan",
            "ciudad_departamento": "Valle de San Juan (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1051",
            "nombre": "Venadillo",
            "ciudad_departamento": "Venadillo (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1052",
            "nombre": "Villahermosa",
            "ciudad_departamento": "Villahermosa (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1053",
            "nombre": "Villarrica",
            "ciudad_departamento": "Villarrica (Tolima)",
            "indicativo": "608",
            "departamento": "Tolima"
        },
        {
            "id": "1054",
            "nombre": "Alcalá",
            "ciudad_departamento": "Alcalá (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1055",
            "nombre": "Andalucía",
            "ciudad_departamento": "Andalucía (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1056",
            "nombre": "Ansermanuevo",
            "ciudad_departamento": "Ansermanuevo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1057",
            "nombre": "Argelia",
            "ciudad_departamento": "Argelia (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1058",
            "nombre": "Bolívar",
            "ciudad_departamento": "Bolívar (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1059",
            "nombre": "Buenaventura",
            "ciudad_departamento": "Buenaventura (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1060",
            "nombre": "Buga",
            "ciudad_departamento": "Buga (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1061",
            "nombre": "Bugalagrande",
            "ciudad_departamento": "Bugalagrande (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1062",
            "nombre": "Caicedonia",
            "ciudad_departamento": "Caicedonia (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1063",
            "nombre": "Calima (Darién)",
            "ciudad_departamento": "Calima (Darién) (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1064",
            "nombre": "Cali",
            "ciudad_departamento": "Cali (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1065",
            "nombre": "Candelaria",
            "ciudad_departamento": "Candelaria (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1066",
            "nombre": "Cartago",
            "ciudad_departamento": "Cartago (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1067",
            "nombre": "Dagua",
            "ciudad_departamento": "Dagua (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1068",
            "nombre": "El Cairo",
            "ciudad_departamento": "El Cairo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1069",
            "nombre": "El Cerrito",
            "ciudad_departamento": "El Cerrito (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1070",
            "nombre": "El Dovio",
            "ciudad_departamento": "El Dovio (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1071",
            "nombre": "El Águila",
            "ciudad_departamento": "El Águila (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1072",
            "nombre": "Florida",
            "ciudad_departamento": "Florida (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1073",
            "nombre": "Ginebra",
            "ciudad_departamento": "Ginebra (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1074",
            "nombre": "Guacarí",
            "ciudad_departamento": "Guacarí (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1075",
            "nombre": "Jamundí",
            "ciudad_departamento": "Jamundí (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1076",
            "nombre": "La Cumbre",
            "ciudad_departamento": "La Cumbre (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1077",
            "nombre": "La Unión",
            "ciudad_departamento": "La Unión (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1078",
            "nombre": "La Victoria",
            "ciudad_departamento": "La Victoria (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1079",
            "nombre": "Obando",
            "ciudad_departamento": "Obando (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1080",
            "nombre": "Palmira",
            "ciudad_departamento": "Palmira (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1081",
            "nombre": "Pradera",
            "ciudad_departamento": "Pradera (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1082",
            "nombre": "Restrepo",
            "ciudad_departamento": "Restrepo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1083",
            "nombre": "Riofrío",
            "ciudad_departamento": "Riofrío (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1084",
            "nombre": "Roldanillo",
            "ciudad_departamento": "Roldanillo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1085",
            "nombre": "San Pedro",
            "ciudad_departamento": "San Pedro (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1086",
            "nombre": "Sevilla",
            "ciudad_departamento": "Sevilla (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1087",
            "nombre": "Toro",
            "ciudad_departamento": "Toro (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1088",
            "nombre": "Trujillo",
            "ciudad_departamento": "Trujillo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1089",
            "nombre": "Tulúa",
            "ciudad_departamento": "Tulúa (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1090",
            "nombre": "Ulloa",
            "ciudad_departamento": "Ulloa (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1091",
            "nombre": "Versalles",
            "ciudad_departamento": "Versalles (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1092",
            "nombre": "Vijes",
            "ciudad_departamento": "Vijes (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1093",
            "nombre": "Yotoco",
            "ciudad_departamento": "Yotoco (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1094",
            "nombre": "Yumbo",
            "ciudad_departamento": "Yumbo (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1095",
            "nombre": "Zarzal",
            "ciudad_departamento": "Zarzal (Valle del Cauca)",
            "indicativo": "602",
            "departamento": "Valle del Cauca"
        },
        {
            "id": "1096",
            "nombre": "Carurú",
            "ciudad_departamento": "Carurú (Vaupés)",
            "indicativo": "608",
            "departamento": "Vaupés"
        },
        {
            "id": "1097",
            "nombre": "Mitú",
            "ciudad_departamento": "Mitú (Vaupés)",
            "indicativo": "608",
            "departamento": "Vaupés"
        },
        {
            "id": "1098",
            "nombre": "Taraira",
            "ciudad_departamento": "Taraira (Vaupés)",
            "indicativo": "608",
            "departamento": "Vaupés"
        },
        {
            "id": "1099",
            "nombre": "Cumaribo",
            "ciudad_departamento": "Cumaribo (Vichada)",
            "indicativo": "608",
            "departamento": "Vichada"
        },
        {
            "id": "1100",
            "nombre": "La Primavera",
            "ciudad_departamento": "La Primavera (Vichada)",
            "indicativo": "608",
            "departamento": "Vichada"
        },
        {
            "id": "1101",
            "nombre": "Puerto Carreño",
            "ciudad_departamento": "Puerto Carreño (Vichada)",
            "indicativo": "608",
            "departamento": "Vichada"
        },
        {
            "id": "1102",
            "nombre": "Santa Rosalía",
            "ciudad_departamento": "Santa Rosalía (Vichada)",
            "indicativo": "608",
            "departamento": "Vichada"
        },
        {
            "id": "1103",
            "nombre": "San Andres",
            "ciudad_departamento": "San Andres (San Andres y Providencia)",
            "indicativo": "608",
            "departamento": "San Andres y Providencia"
        },
        {
            "id": "1104",
            "nombre": "Rionegro",
            "ciudad_departamento": "Rionegro (Antioquia)",
            "indicativo": "604",
            "departamento": "Antioquia"
        }
    ];
}

export default getCities;
