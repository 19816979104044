import React, {FC, useEffect, useState} from 'react';
import {Collapse} from "react-collapse";
import {invoiceResume} from "../types";
import Flex from "../../../ui/flex";
import Invoice from "./invoice";
import {InvoicesContainer} from "./styles"
import {parseInvoicetoResume} from "../helper";

type Props = {
    invoices:invoiceResume[];
    loading:boolean;
}

const SectionInvoices:FC<Props> = (props) => {

    let {invoices,loading} = props;
    let [show,set] = useState(false);

    useEffect(()=>{
        if(loading && show){
            set(!show);
        }
    },[])

    return (
        <InvoicesContainer className={"wc pb-4"}>
            <Flex className={"wc py-3 collapse_separator mt-3 "}>
                <Flex flex={"1 0 70%"} jc={"flex-start py-2"}>
                    <strong className={"fb"}><small>Facturas pagadas</small></strong>
                </Flex>
                <Flex flex={"0 0 40px"}>
                    <button disabled={loading} onClick={()=> set(!show)} className={`btn-chevron ${show ? "active" : ""}`}>
                        <em className={"icon-icon-chevron"}></em>
                    </button>
                </Flex>
            </Flex>
            <Collapse isOpened={show} className={"wc ro"}>
                <Flex className={"wc"} jc={"flex-start"} alg={"flex-start"}>
                    {invoices && invoices.map((inv:any,key:number)=> <Invoice id={key} key={key} data={parseInvoicetoResume(inv)}/>)}
                </Flex>
            </Collapse>
        </InvoicesContainer>
    )
}

export default React.memo(SectionInvoices);