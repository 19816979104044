//FRONT
const urlFront = process.env.REACT_APP_URL_FRONT;

//MULTIMEDIA
const bannerUrl = process.env.REACT_APP_AWS_S3;

//AMON
const amonServices = process.env.REACT_APP_AMON_SERVICES;

const urlMultimedia = process.env.REACT_APP_URL_MULTIMEDIA;
const checkOutPruebas = JSON.parse(`${process.env.REACT_APP_CHECKOUT_TEST}`);

/*** Recursos Rest Recaudo Epayco  */
//URLS DE CONSULTA Y APLICADO DE PAGOS
const EPAYCO_REST_RECAUDO_API_INVOICE =
  process.env.REACT_APP_EPAYCO_REST_RECAUDO_API_INVOICE;

//URLS DE CONSULTA Y APLICADO DE PAGOS
const EPAYCO_REST_RECAUDO_API_CONFIRM =
  process.env.REACT_APP_EPAYCO_REST_RECAUDO_API_CONFIRM;

//URLS DE CONSULTA CHECKOUT PRINCIPAL
const EPAYCO_APP_CHECKOUT = process.env.REACT_APP_EPAYCO_APP_CHECKOUT;

const REACT_APP_GEO_IP_URL = process.env.REACT_APP_GEO_IP_URL;

const REACT_APP_CHECKOUT_SUSCRIPTION =
  process.env.REACT_APP_CHECKOUT_SUSCRIPTION;

// const REACT_APP_CHECKOUT_TEST = process.env.NODE_ENV === "production" ? false : false;
const REACT_APP_CHECKOUT_TEST = JSON.parse(`${process.env.REACT_APP_CHECKOUT_TEST_SUSCRIPCION}`);

//.............................................. AMBIENTE DE LOS ENDPOINTS
const REACT_APP_AMBIENTE = process.env.REACT_APP_AMBIENTE;

//.........................................................

// .......................................................... LLAVE PÚBLICA
const REACT_APP_MOVISTAR_RECAUDO_PUBLIC_KEY =
  process.env.REACT_APP_MOVISTAR_RECAUDO_PUBLIC_KEY;
//..........................................................

// .......................................... LLAVE PÚBLICA TELEBUCARAMANGA
const REACT_APP_TELEBUCARAMANGA_RECAUDO_PUBLIC_KEY =
  process.env.REACT_APP_TELEBUCARAMANGA_RECAUDO_PUBLIC_KEY;
//..........................................................

// .................................................. LLAVE PÚBLICA METROTEL
const REACT_APP_METROTEL_RECAUDO_PUBLIC_KEY =
  process.env.REACT_APP_METROTEL_RECAUDO_PUBLIC_KEY;
//..........................................................

// ............................................... URL CONSULTA FACTURAS FS
const REACT_APP_MOVISTAR_DOMINIO_FACTURAS =
  process.env.REACT_APP_MOVISTAR_DOMINIO_FACTURAS;
//..........................................................

const REACT_APP_MOVISTAR_DOMINIO_RECAUDO =
  process.env.REACT_APP_MOVISTAR_DOMINIO_RECAUDO;
//..........................................................

// .............................................. URL CONSULTA FACTURAS SCL
const REACT_APP_MOVISTAR_DOMINIO_FACTURAS_SCL =
  process.env.REACT_APP_MOVISTAR_DOMINIO_FACTURAS_SCL;
// .........................................................

// ............................................ DOMINIO CONFIGURADO RECADUO
const REACT_APP_MOVISTAR_DOMINIO = process.env.REACT_APP_MOVISTAR_DOMINIO;
//...........................................................

// .............................. DOMINIO CONFIGURADO RECADUOTELEBUCARAMANGA
const REACT_APP_TELEBUCARAMANGA_DOMINIO =
  process.env.REACT_APP_TELEBUCARAMANGA_DOMINIO;
//...........................................................

// .............................. DOMINIO CONFIGURADO RECADUOTELEBUCARAMANGA
const REACT_APP_TELEBUCARAMANGA_BAND =
  process.env.REACT_APP_TELEBUCARAMANGA_BAND;
//...........................................................

// .................................... DOMINIO CONFIGURADO RECADUO METROTEL
const REACT_APP_METROTEL_DOMINIO = process.env.REACT_APP_METROTEL_DOMINIO;
//...........................................................

// .................................... CONSULTA EL CART
const EPAYCO_REST_RECAUDO_API_CONSULT_CART = process.env.REACT_APP_CONSULT_CART_DOMAIN;
//...........................................................

// .............................................. UPDATE CART VALUES
const UPDATE_CART_VALUES = process.env.REACT_APP_UPDATE_CART_VALUES;
//..................................................................

// .............................................. DELETE CART VALUES
const DELETE_CART = process.env.REACT_APP_DELETE_CART_VALUES;
//..................................................................


const REACT_APP_GET_TOKEN = `${REACT_APP_AMBIENTE}/api/recaudo/get/token`;

const REACT_APP_MOVISTAR_GET_PAY = process.env.REACT_APP_MOVISTAR_CONSULTA_PAGO;

const REACT_APP_MOVISTAR_ID_COMERCIO = process.env.REACT_APP_MOVISTAR_ID_COMERCIO;

const REACT_APP_ALERTA_WSPAGOSONLINE = process.env.REACT_APP_ALERTA_WSPAGOSONLINE || "";

const urlS3 = process.env.REACT_APP_URL_S3_MOVISTAR_RECAUDO || "";

const advertisingMovil = process.env.REACT_APP_URL_S3_MOViISTAR_RECAUDO_MOVIL || "";

const CaptchaKey = process.env.REACT_APP_CAPTCHA_KEY_V3 || "";
/**
 *
 * @type {{urlFront: (string), url: (string), urlMultimedia: string, checkOutPruebas: boolean}}
 */
const config = {
  urlFront,
  urlS3,
  advertisingMovil,
  bannerUrl,
  urlMultimedia,
  checkOutPruebas,
  amonServices,
  EPAYCO_REST_RECAUDO_API_INVOICE,
  EPAYCO_REST_RECAUDO_API_CONFIRM,
  REACT_APP_GEO_IP_URL,
  REACT_APP_CHECKOUT_SUSCRIPTION,
  REACT_APP_CHECKOUT_TEST,

  // CREDENCIALES PRUEBAS SUSCRIPCIONES
  REACT_APP_MOVISTAR_RECAUDO_PUBLIC_KEY,
  REACT_APP_TELEBUCARAMANGA_RECAUDO_PUBLIC_KEY,
  REACT_APP_METROTEL_RECAUDO_PUBLIC_KEY,
  REACT_APP_MOVISTAR_DOMINIO_RECAUDO,
  REACT_APP_TELEBUCARAMANGA_DOMINIO,
  REACT_APP_TELEBUCARAMANGA_BAND,
  REACT_APP_METROTEL_DOMINIO,
  REACT_APP_MOVISTAR_DOMINIO_FACTURAS,
  REACT_APP_MOVISTAR_DOMINIO_FACTURAS_SCL,

  // CART
  EPAYCO_REST_RECAUDO_API_CONSULT_CART,
  UPDATE_CART_VALUES,
  DELETE_CART,

  REACT_APP_MOVISTAR_DOMINIO,
  REACT_APP_GET_TOKEN,
  REACT_APP_AMBIENTE,
  EPAYCO_APP_CHECKOUT,

  REACT_APP_MOVISTAR_GET_PAY,
  REACT_APP_MOVISTAR_ID_COMERCIO,

  REACT_APP_ALERTA_WSPAGOSONLINE,
  CaptchaKey,

  // DESABILITAR TABS
  MOVISTAR_TAB: {
    before: '2020-08-16 05:00:00:00 pm',
    after: '2020-08-18 04:00:00:00 am'
  },
  TELEBUCARAMANGA: {
    before: '2020-08-14 03:00:00:00 pm',
    after: '2020-08-14 04:40:00:00 pm'
  },
  METROTEL: {
    before: '2020-08-14 03:00:00:00 pm',
    after: '2020-08-14 04:40:00:00 pm'
  },
  alertas: {
    facturas: "Estamos presentando fallas técnicas. Por favor intenta más tarde.",
    token: "Estamos presentando fallas técnicas. Por favor intenta más tarde.",
    conexion: "Por favor intenta de nuevo.\nSi no muestra tu saldo la red que estás utilizando genera un bloqueo y no permite entregarte la información. Por favor intenta con otra red o en otro dispositivo.",
    saldo0: "No se encontraron facturas pendientes de pago relacionadas al número de consulta.",
    sinfacturas: "No se encontraron facturas pendientes de pago relacionadas al número de consulta."
  }
};
export default config;