import config from "../../../config/config";

type keys = {
    [key :number]:string
}

export let llaves:keys = {
    1 : config.REACT_APP_MOVISTAR_RECAUDO_PUBLIC_KEY || '',
    2 : config.REACT_APP_TELEBUCARAMANGA_RECAUDO_PUBLIC_KEY || '',
    3 : config.REACT_APP_METROTEL_RECAUDO_PUBLIC_KEY || '',
}

export const comerce = [
    "movistar",
    "telebucaramanga",
    "metrotel",
];

export const comerceNumber:any = {
    movistar       :1,
    telebucaramanga:2,
    metrotel       :3,
};
