import React, {FC, useState} from 'react';
import {Container} from "./styles";
import ShowError from "./showError";
import Flex from "../flex";
import {colors} from "../index";
import SelectCities from "./selectCities";
import NumberFormat from "react-number-format";


interface Props {
    value: string;
    name?: string;
    onChange:any;
    disabled: boolean;
    flex?:string;
    className?:string,
    icon?:string;
    error?:string | undefined | false;
    options?:string,
    type?:string;
    theRef?:any;
    placeholder?:string;
    after?:FC
    typeLine?:string
    typeInvoice?:string
}

const Field: React.FC<Props> = (props) => {

    const [focus,set] = useState(false);

    return (
        <Container
            error={!!props.error}
            flex={props.flex || '1 0 100%'}
            icon={!!props.icon}
            colorIcon={!!props.error ? 'red' : props.disabled ? colors.disabled : (focus ? colors.blue : colors.blueDark )}
            after={!!props.after}
        >
            {!!props.icon &&
            <Flex className="icon" direction={"column"}>
                <i className={"icon-icon-" + props.icon}> </i>
            </Flex>}


            {props.type === "number" &&
                <>
                    <NumberFormat
                        onFocus={()=> set(true)}
                        onBlur={()=> set(false)}
                        ref={props.theRef}
                        disabled={props.disabled}
                        className={"to-right wc"}
                        value={props.value}
                        format={props.typeLine === "fijo" && props.typeInvoice ==="telefono"?"### ### #":"### ### ## ###"}
                        onValueChange={ (values:any)=> {
                            const {value} = values;
                            let val = value.trim();
                            let obj = {target:{value:val}}
                            props.onChange(obj)
                        }}
                        placeholder={props.placeholder }
                    />
                </>
            }

            {props.type === "select" &&
             <SelectCities
                 Ref={props.theRef}
                 icon={!!props.icon}
                 setValue={ (e:any)=>{
                     let obj:{} = {
                         target:{
                             value:e ? e.value : 0
                         }
                     }
                     props.onChange(obj)
                 }}
                 setBlur={()=> set(false)}
                 setFocus={()=>set(true)}
                 error={!!(props.error)}
                 disabled={props.disabled}
                 value={props.value}
             />
            }

            {!props.type &&
            <input
                ref={props.theRef}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                onFocus={()=> set(true)}
                onBlur={()=> set(false)}
                disabled={props.disabled}
                placeholder={props.placeholder }
            />
            }

            {props.after &&
            <div className={"cont-after"}>
                <props.after/>
            </div>
            }

            <ShowError error={props.error}/>
        </Container>
    )
}

export default React.memo(Field);