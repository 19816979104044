import TagManager from 'react-gtm-module'

export const initializeTagManager = ()=> {
 
    const  tagManagerArgs =  {
        gtmId: 'GTM-KBVPPT',
        async:true
    };
    TagManager.initialize(tagManagerArgs)
               
}

export const setNewTagManager = (dataLayer:any)=>{
    let tagManagerArgs =  {
        gtmId: 'GTM-KBVPPT',
        dataLayer
    };
    TagManager.dataLayer(tagManagerArgs)
    
}
