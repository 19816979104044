import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import Flex from "./../ui/flex";
import moment from 'moment';
import {PageInMaintenance} from "./pageInMaintenance";

const Container = styled(Flex)`
    position:relative;
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index:9999;
    background:rgba(0,0,0,.8);
    color:white;
`;

const Mantenimiento:React.FC = (props) => {

    type DateM = {
        mantenimiento:boolean;
    }

    const [state, set] = useState<DateM>({
        mantenimiento:false
    })

    const validatePageMaintenance = async () => {
       
         set({ ...state, mantenimiento:false });
            const pageInMaintenance = await PageInMaintenance();

          if (pageInMaintenance.result){
            let date:any = pageInMaintenance.dateRecaudo;
                if (moment().isBetween(date.beforeTime, date.afterTime)) {
                    set({ ...state, mantenimiento:true });
                }

          }else{
              set({ ...state, mantenimiento:false }); 
          }
        }
        
        useEffect(()=>{
            validatePageMaintenance();
            if(state.mantenimiento) set({ ...state, mantenimiento:true })

        },[state.mantenimiento])

    let validate = state.mantenimiento;

    return <React.Fragment>
        {validate && 
        <Container>

                {/* <Icon hg={"70px"} wd={"80px"} /> */}
                <h1>Servicios en mantenimiento</h1>
                <p>Nuestros servicios están temporalmente fuera de línea, perdone las molestias.</p>


        </Container>
        }
    </React.Fragment>
}


export default (React.memo(Mantenimiento));
