declare const window: any
export const setNewTagCI360 = (dataLayer: any) => {

    const dataLayerSend = formatDataLayer(dataLayer);
    window.ci360("send", dataLayerSend)
}

const base = {
    "eventName": "custom",
    "event": "custom",
    "customGroupName": "custom",
    "customRevenue": "0",
    "url_paso": window.location.protocol + "//" + window.location.host
}

const formatDataLayer = (dataLayer: any) => {

    let newDataLayer = dataLayer;
    newDataLayer.empresa = capitalize1Letter(newDataLayer.empresa);
    if (newDataLayer.operacion === "fijo") {
        newDataLayer.operacion = "fija";
    }
    newDataLayer.operacion = capitalize1Letter(newDataLayer.operacion);
    newDataLayer.tipo_referencia = capitalize1Letter(newDataLayer.tipo_referencia);
    return { ...base, ...newDataLayer };
}
const capitalize1Letter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);;
}

export const formatConsultFormData = (data: any, apiEventKey: string = "factura-datos-generales", newData: any = {}) => {
    let tipo_referencia = data.tipoFijo,
        numero_pago = data.paymentRef,
        ciudad = "",
        operacion = data.invoiceType,
        empresa = data.comerce;
    if (empresa !== "movistar") {
        if (operacion === "factura_id" || tipo_referencia === "factura_id") {
            tipo_referencia = "factura_id";
        } else {
            tipo_referencia = "telefono";
        }
        operacion = "fijo";
    }
    if (tipo_referencia === "telefono") {
        if (empresa === "metrotel") {
            ciudad = "605";
        } else if (empresa === "telebucaramanga") {
            ciudad = "607";
        } else {
            ciudad = numero_pago.substring(0, 3);
            numero_pago = numero_pago.slice(3);
        }
        if (numero_pago.startsWith(ciudad)) {
            numero_pago = numero_pago.slice(3);
        }

    } else if (tipo_referencia === "factura_id") {
        tipo_referencia = "Referencia de pago";
    }
    return {
        ...newData,
        "apiEventKey": apiEventKey,
        "empresa": empresa,
        "operacion": operacion,
        "tipo_referencia": tipo_referencia,
        "ciudad": ciudad,
        "numero_pago": numero_pago
    };
}

export const formatInDetails = (ultraFacturas: any, apiEventKey: string = "factura-detalle", responseTR: string = "") => {
    const factura = ultraFacturas.facturas[0],
        comerce = sessionStorage.getItem("filial");
    let tipoFijo = "",
        paymentRef = factura.extra12,
        numero_linea = factura.identificacionEmpresa;

    if (comerce === "metrotel") {
        factura.extra1 = factura.consulta.option;
        numero_linea = factura.consulta.referencia;
        paymentRef = numero_linea;
    } else if (factura.extra1 === "fijo") {
        tipoFijo = factura.extra2 === "true" ? "factura_id" : "telefono";
        paymentRef = numero_linea;
    } else if (comerce === "telebucaramanga") {
        tipoFijo = factura.consulta.option;
        paymentRef = numero_linea;
    } else if (factura.extra1 === "movil") {
        paymentRef = numero_linea;
    }
    let newData: any = {
        numero_factura: factura.facturaId,
        valor_pagar: factura.total,
        numero_linea: factura.identificacionEmpresa
    };
    if (responseTR !== "") {
        newData["estado_transaccion"] = responseTR;
    }
    return formatConsultFormData({
        comerce: comerce,
        invoiceType: factura.extra1,
        tipoFijo: tipoFijo,
        paymentRef: String(paymentRef),

    }, apiEventKey, newData)
}