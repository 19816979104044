import axios from "axios";
import config from "../../config/config";

export const LogDetalleCheckout = async (data:any) =>{
    let obj = {result:false};
    try{
        await axios({
            method :'post',
            url    :`${config.REACT_APP_AMBIENTE}/api/recaudo/log_checkout`,
            data:{
                ...data,
                dataPayment: JSON.parse(sessionStorage.getItem('dataPayment') || ""),
                invoicesPaid: JSON.parse(sessionStorage.getItem('invoicesPaid') || ""),
                dataOrigin: JSON.parse(sessionStorage.getItem('dataOrigin') || "")
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((resp:any)=>{
                if(resp.data.success){
                    obj.result = true;
                }
            })
            .catch(e=>{
                console.log('::::: Error al actualizar feetypes ::::', e)
            })
    }
    catch (e) {
        console.log('::::: Error al intentar actualizar feetypes ::::', e)
    }
    return obj;
}