import React,{FC} from 'react';
import DataItem from "../../DataItem/DataItem";
import {comercio} from "../types";

type Props ={
    data:comercio
    loading:boolean;
}

const SectionComerce:FC<Props> = (props) => {

    let {empresa,nit,fechaHora,ipOrigen} = props.data;
    let {loading} = props;

    return (
        <div className={"wc"}>
            <div className={"wc py-3"}>
                <strong className={"fb"}><small>Comercio</small></strong>
            </div>
            <DataItem label={"Empresa"}      value={empresa}    loading={loading} uppercase={true} />
            <DataItem label={"NIT"}          value={nit}        loading={loading} uppercase={false} />
            <DataItem label={"Fecha y hora"} value={fechaHora}  loading={loading} uppercase={false} />
            <DataItem label={"IP origen"}    value={ipOrigen}   loading={loading} uppercase={false} />
        </div>
    )
}

export default React.memo(SectionComerce);