import React, {useState} from 'react';
import Field from "../../ui/field/field";
import Btn from "../../ui/btn";
import Flex from "../../ui/flex";
import {colors} from "../../ui";
import { Formik, isString } from 'formik';
import {SendEmailService} from "./SendEmailService";
import { tagManagerEvent } from '../../../utils/tagManager/tagManagerConfig';

type Props ={
    setLoading:(e:boolean)=> void;
    disabled:boolean;
    ref_payco:any | string;
    setTagManagerEmail:any;
}

const SendEmail:React.FC<Props> = (props) => {

    type Data = {
        error?:string;
        proccesing:boolean;
        enviado:string;
    }

    const [state,set] = useState<Data>({
        error:"",
        proccesing:false,
        enviado:''
    })

    const emailClick = () => {
        let empresaPago = props.setTagManagerEmail[0];
        let tipoCliente = props.setTagManagerEmail[1];
        let eventAction = props.setTagManagerEmail[2];
        tagManagerEvent('enviar por email', eventAction, empresaPago, tipoCliente);
    };

    const sendMailOptions = async (props:any, values:any) => {
        try {
            if (props.ref_payco.length > 0 && !isString(props.ref_payco)) {
                for (let index = 0; index < props.ref_payco.length; index++) {
                    result = await SendEmailService({
                        email: values.email,
                        ref_payco: props.ref_payco[index]
                    });
                }
            } else {

                if (props.ref_payco == null || props.ref_payco.length == 0) {
                    set({
                        ...state,
                        proccesing: false,
                        error: 'Debe existir Referencia ePayco'
                    });
                    return;
                }
            
                result = await SendEmailService({
                    email: values.email,
                    ref_payco: props.ref_payco
                });
                if (result.result) {
                    set({
                        ...state,
                        proccesing: false,
                        enviado: '¡Correo enviado exitosamente!'
                    });
                    values.email = '';
                    setTimeout(() => { set({ ...state, enviado: '' }) }, 6000);
                    return;
                }
            } 
            
            if (!result.result) {
                set({
                    ...state,
                    proccesing: false,
                    error: result.message
                });
            } else {
                set({
                    ...state,
                    proccesing: false,
                    enviado: '¡Correo enviado exitosamente!'
                });
            }
            values.email = '';

            setTimeout(() => { set({ ...state, enviado: '' }) }, 6000);
        } catch (error) {
            if (props.ref_payco == null) {
                values.email = '';
                set({
                    ...state,
                    proccesing: false,
                    error: 'Debe existir una referencia ePayco'
                });
            } else {
                values.email = '';
                set({
                    ...state,
                    proccesing: false,
                    error: error.message
                });
            }
           
        }
    };

    let result:any;
    // @ts-ignore
    return (
        <Flex className={"wc mb-4"} alg={"flex-start"}>
            <Formik
                initialValues={{ email: ''}}
                validate={ (values) => { 
                    const errors:any = {};
                    if (!values.email) {
                    errors.email = 'Debe ingresar un correo electrónico.';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Correo electrónico no válido.';
                }
                    return errors;
                }}
                onSubmit={async (values, { resetForm }) => {
                
                    set({ ...state, proccesing: true });
                    sendMailOptions(props, values);

                }}
                
            >
                {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      setFieldValue
                }) => (
                    <form
                        className={"wc"}
                        onSubmit={e=>{
                        e.preventDefault();
                        handleSubmit();
                        emailClick();
                    }}>
                        <Flex className={"wc"}>
                            <Field
                                value={values.email}
                                icon={"mail"}
                                flex={"1 0 60%"}
                                onChange={(e:any) => setFieldValue('email',e.target.value)}
                                disabled={state.proccesing || props.disabled}
                                error={state.error || ((errors.email && touched.email) && errors.email)}
                                placeholder={"Correo electrónico"}
                            />
                            <Btn
                                color={colors.blue}
                                flex={"1 0 80px"}
                                className={"ps-2 fz18"}
                                big
                                disabled={props.disabled}
                                loading={state.proccesing }
                            >
                                Enviar
                            </Btn>
                            <div className={"wc"}>
                                <small style={{color: state.enviado ? colors.green : colors.black }}>{
                                    state.enviado ?
                                        state.enviado
                                        :'Envia o comparte el comprobante por correo'
                                }</small>
                            </div>
                        </Flex>
                    </form>
                    )}
            </Formik>
        </Flex>
    )
}

export default React.memo(SendEmail);