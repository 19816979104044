import React,{FC} from 'react';
import {View} from "@react-pdf/renderer";
import {invoiceResume} from "../../resumeMI/types";
import InvoiceItemPdf from "./invoice";
import {styles} from "./styles";
import {parseInvoicetoResume} from "../../resumeMI/helper";

type Props = {
    invoices:invoiceResume[];
}

const Renderinvoices:FC<Props> = (props) => {

    let {invoices} = props;

    return (
        <View style={styles.containerInvoices}>
            {invoices && invoices.map((inv:any,key:number)=> <InvoiceItemPdf data={parseInvoicetoResume(inv)} key={key} id={key}/>)}
        </View>
    )
}

export default React.memo(Renderinvoices);