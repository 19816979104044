import React, {lazy, Suspense, useEffect} from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import Header from "./components/header/header";
import Loading from "./components/ui/loading/loading";
import {colors} from "./components/ui";
import Flex from "./components/ui/flex";
import {validateRoute} from "./utils/validateRoute";
import {withRouter} from "react-router-dom";
import {checkoutRender} from "./components/checkout";
import {WrapForm} from "./components/ui";
import Mantenimiento from "./components/maintenance";
import {initializeTagManager} from "./utils/tagManager";
import ResumeMI from "./components/resumen/resumeMI/resumeMI";

const Home = lazy(() => import('./components/home/home'));
const Detail = lazy(() => import('./components/detail/details'));
const Suscription = lazy(() => import('./components/suscriptions/suscriptions'));
const Resume = lazy(() => import('./components/resumen/resume'));
const FORM = lazy(() => import('./components/consultForm/consultForm'));



interface Props {
    history: any,
}

const App: React.FC<Props> = (props) => {

    let goRoute = (e: string) => {
        props.history.push(e);
    }

    useEffect(() => {
        validateRoute(goRoute);
        checkoutRender();
    },[])

    initializeTagManager();

    return (

        <div className="main-container">
            <Mantenimiento/>
            {props.history.location.pathname !== "/alone" && <Header/>}

                <Suspense fallback={
                    <Flex className={"wc py-5"}>
                        <Loading
                            size={100}
                            sizeBr={10}
                            colorLine={colors.blue}
                            colorPath={"#e4e4e4"}
                        />
                    </Flex>
                }>
                <Switch>
                    <Route exact path={'/'} render={() => <Home/>}/>
                    <Route exact path={'/corporativo'} render={() => <Home/>}/>
                    <Route exact path={'/detail'} render={() => <Detail/>}/>
                    <Route exact path={'/suscription'} render={() => <Suscription/>}/>
                    <Route exact path={'/resume'} render={() => <Resume/>}/>
                    <Route exact path={'/resume_multiples_facturas'} render={() => <ResumeMI/>}/>
                    <Route exact path={'/alone'} render={() => <WrapForm><FORM/></WrapForm>}/>
                    <Redirect from='*' to='/' />
                    </Switch>
            </Suspense>
        </div>

    );
}

export default withRouter(App);
