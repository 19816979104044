import {
    comercio,
    detalleTransaccion, invoiceResume,
    responseTransaction
} from "./types";
import {invoiceFormat} from "../../typesInvoices/invoiceFormat";



///////////////////////////////////////
// EXTRACT DATA FOR TRANSACTION SECITON
export const extractTransactionData = (
    {
        x_description,
        x_customer_ip,
        x_transaction_date,
        x_transaction_id,
        x_cardnumber,
        x_response,
        x_approval_code,
        x_amount,
        x_transaction_state,
        x_ref_payco,
        x_signature
    }:responseTransaction
) =>{

    let objTransaction: detalleTransaccion =
        {
        valor        : x_amount.toString(),
        estado       : x_transaction_state,
        autorizacion : x_approval_code,
        referencia   : x_ref_payco.toString(),
        }

    return objTransaction;
}


////////////////////////////////////
//  EXTRACT DATA FOR COMERCE SECTION
export const extractComerceData = (
    {
        x_customer_ip,
        x_transaction_date,
        x_business,
    } : responseTransaction
)=>{

    let objCommerce:comercio =
        {
            empresa:x_business,
            nit:"830.122.566-1",
            fechaHora:x_transaction_date,
            ipOrigen:x_customer_ip

        }
        return objCommerce;
}



export const parseInvoicetoResume = (
    {
        total,
        identificacionEmpresa,
        extra6,
        extra7,
        extra8,
        id
    }:invoiceFormat
)=>{
    let obj:invoiceResume ={
        referencia        : identificacionEmpresa,
        numeroRecibo      : id,
        servicioMovil     : extra6,
        servicioTelefonia : extra7,
        servicioAdicional : extra8,
        total             : total,
        descripcion       : ''
    }
    return obj;
}