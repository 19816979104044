import React,{FC} from 'react';
import DataItem from "../../DataItem/DataItem";
import {detalleTransaccion} from "../types";

type Props ={
    data:detalleTransaccion
    loading:boolean;
}

const SectionTransaction:FC<Props> = (props) => {

    let {loading} = props;
    let {valor,
        estado,
        autorizacion,
        referencia} = props.data;

    return (
        <div className={"wc position-relative"}>
            <div className={"wc py-3"}>
                <strong className={"fb"}><small>Detalles transacción</small></strong>
            </div>
            <DataItem label={"Valor"}               value={valor}         loading={loading} uppercase={false} amount/>
            <DataItem label={"Estado transacción"}  value={estado}        loading={loading} uppercase={true} />
            <DataItem label={"Autorización CUS"}    value={autorizacion}  loading={loading} uppercase={false} />
            <DataItem label={"Referencia ePayco"}   value={referencia}    loading={loading} uppercase={false} />
        </div>
    )
}

export default React.memo(SectionTransaction);