import React, { ReactNode } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import config from '../../config/config';

interface Props {
  children: ReactNode,
}

const CaptchaComponent: React.FC<Props> = props => {
  const onVerify = () => {
    return;
  };
  return <GoogleReCaptchaProvider
    reCaptchaKey={config.CaptchaKey}
    language="ES"
    useRecaptchaNet={true}
  >
    <GoogleReCaptcha onVerify={onVerify} />
    {props.children}
  </GoogleReCaptchaProvider >;
};
export default CaptchaComponent;
