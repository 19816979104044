import styled from "@emotion/styled";

export const InvoicesContainer = styled.div`
    width:100%;
    
    .invoice{
       border-bottom:1px solid #e4e4e4; 
    }
    
    .collapse_separator{
        border-top:1px solid #e4e4e4;
        
        .btn-chevron{
            background:transparent;
            cursor:pointer;
            align-sef:stretch;
            height:44px;
            &:hover{
                background:rgba(0,0,0,.1);
            }
        }
        
        .btn-chevron.active{
             transform:rotate(180deg)
        }
    }
    
    
`