import axios from "axios";
import config from "../../config/config";


export const PageInMaintenance = async () =>{

    let obj:any = {};

    try{
        await axios({
            method:'get',
            url:`${config.amonServices}/pages/in/maintenance/recaudo`,
        })
            .then((response:any)=>{
               
                if(response.data.success){
                    //Results only for recaudo windows general
                    let dateRecaudo = response.data.data.recaudo;
                    let beforeTime  = dateRecaudo.beforeTime
                    let afterTime   = dateRecaudo.afterTime;
                    let status      = dateRecaudo.status;
       
                    obj.dateRecaudo = {
                        beforeTime,
                        afterTime,
                        status,
                    };
                    obj.result = true;

                    return obj;

                }else{

                    obj.status = false;
                    obj.message = "Error: no se enontraron fechas";
                    return obj;
                }
            })
            .catch(e=>{
                obj.status = false;
                console.error(e);
            })
    }
    catch (e) {
        obj.status = false;
        console.error(e);
    }
    return obj;
}
