import React,{FC} from 'react';
import DataItem from "../../DataItem/DataItem";
import {invoiceResume} from "../types";
import {comerceNumber} from "../../../consultForm/Helpers/constants";

type Props = {
    data:invoiceResume,
    id:number;
}

function validaStringNumber(val:string|number){
    return typeof val === "number" ? val.toString() : val;
}

const Invoice:FC<Props> = (props) => {

    let filial:number = comerceNumber[sessionStorage.getItem('filial') || "movistar"];
    let hide = filial !== 1;

    let {
        numeroRecibo,
        descripcion,
        referencia,
        servicioTelefonia,
        servicioMovil,
        servicioAdicional,
        total
    } = props.data

    return (
        <div className={"col-12 col-md-6 invoice"}>
            <div className={"wc py-2"}>
                <strong className={"fb"}><small>Factura {props.id + 1}</small></strong>
            </div>
            <DataItem hide={hide} loading={false} label={"Número de recibo"} value={numeroRecibo}                          amount={false} />
            <DataItem hide={hide} loading={false} label={"Descripción"}      value={descripcion}                           amount={false} />
            <DataItem             loading={false} label={"Referencia"}       value={validaStringNumber(referencia)}        amount={false} />
            <DataItem hide={hide} loading={false} label={"Equipo"}           value={validaStringNumber(servicioTelefonia)} amount={true} />
            <DataItem hide={hide} loading={false} label={"Servicio"}         value={validaStringNumber(servicioMovil)}     amount={true} />
            <DataItem hide={hide} loading={false} label={"Adicional"}        value={validaStringNumber(servicioAdicional)} amount={true} />
            <DataItem             loading={false} label={"Valor total"}      value={validaStringNumber(total)}             amount={true} />
        </div>
    )
}

export default React.memo(Invoice);