import React, {FC} from "react";
import {Image, Text, View} from "@react-pdf/renderer";
import {styles} from "./styles";
import logo_movistar from "../../../../img/logo_movistar.png";
import logo_ePayco from "../../../../img/logo_ePayco.png";


type Pt ={state:string;}


export let HeaderBrand:FC<Pt> = (props) =>{
    let {state} = props;
    return (
        <>
            <Image style={styles.icon} src={logo_movistar} />
            <View style={styles.title}>
                <Text>Transación {state}</Text>
            </View>
        </>
    )
}

export const FooterBrand = ()=>{
    return (
        <View style={styles.footer}>
            <Text>Pagos procesados por</Text>
            <Image style={styles.iconFooter} src={logo_ePayco} />
        </View>
    )
}