import React, {FC} from "react";
import {detalleTransaccion} from "../../resumeMI/types";
import {View} from "@react-pdf/renderer";
import {styles} from "./styles";
import {DataItem} from "./Dataitem";

type PropsDT ={
    data:detalleTransaccion
}

export const DataTransaction:FC<PropsDT>   = (props)=>{
    let {valor, estado, autorizacion, referencia} = props.data;
    return (
        <View style={styles.dataItemCont}>
            <DataItem label={"Valor"}               amount={true} value={valor}        uppercase={false} />
            <DataItem label={"Estado transacción "} amount={false} value={estado}       uppercase={true} />
            <DataItem label={"Autorización CUS"}    amount={false} value={autorizacion} uppercase={false} />
            <DataItem label={"Referencia ePayco"}   amount={false} value={referencia}   uppercase={false} />
        </View>
    )
}