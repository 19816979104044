import React from 'react';
import logoMovistar from "../../img/logo_movistar.png";
import { Container } from "./styles";
import Flex from "../ui/flex";
import logo_ePayco from "../../img/logo_ePayco.png";


const Header: React.FC = (props) => {
    return (
        <div className='headerContainer'>
            <div className="headerWeb">
                <Container className={"py-3"}>
                    <Flex alg={"center"} jc={"center"} flex={"0 0 0"} >
                        <img src={logoMovistar} alt="" />
                    </Flex>
                </Container>
            </div>
            <div className="headerMovil">
                <Container className={"py-3 headerMvContent"}>
                    <Flex alg={"center"} jc={"center"} flex={"0 0 0"} >
                        <img src={logoMovistar} alt="" className='imgMovistarHeader'/>
                        <p className={"pagosEpaycoFont"}>
                            <i className={"icon-icon-seguridad"}></i>
                            Pagos procesados por &nbsp;
                            <img src={logo_ePayco} alt="" className='pagosEpayco'  />
                        </p>
                    </Flex>
                </Container>
            </div>

        </div>


    )
}

export default React.memo(Header);