import axios from "axios";
import config from "../../../config/config";

type  Consulta = {
   ref_payco:string;
   x_ref_payco:boolean
}


export const GetResume = async  (data:Consulta) =>{

    let obj:{
        result:boolean;
        [key:string]:any;
    }= {result:false}

    try {
        await axios({
            method:'post',
            url:`${config.amonServices}/payment/on/response`,
            data
        })
            .then((resp:any)=>{
                if(resp.status === 200){
                    obj.result = true
                    obj.data   = resp.data;
                }
            })
            .catch(e=>{
                console.log("::::::: ERROR OBETENIENDO RESPONSE ::::",e)
            })
    }
    catch (e) {
        console.log("::::::: ERROR INTENTANDO OBTENER EL RESPONSE ::::",e)
    }
    return obj;
}