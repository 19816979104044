let getVariableParam = (parametro: string) => {
  var results = new RegExp('[?&]' + parametro + '=([^&#]*)').exec(
    window.location.href
  );
  if (results == null) {
    return null;
  } else {
    return decodeURI(results[1]) || 0;
  }
};

export let getDataFromUrl = () => {
  let NameKeys = [
    'ref_pago',
    'tipo_servicio',
    'is_ref_number',
    'canal',
    'tipo_pago',
    'novum',
    'urlcallback',
    'indicativo',
    'extra10',

    //cuando sea suscripciones
    'suscription',
    'linea',
    'numero',
    'checkout',
    //nuevos tags
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];

  type comm = {
    movistar: number;
    telebucaramanga: number;
    metrotel: number;
  };

  const comerce: comm = {
    movistar: 1,
    telebucaramanga: 2,
    metrotel: 3
  };

  type keyVal = {
    [key: string]: any;
  };

  if (window.location.search !== '') {
    let data: keyVal = {};
    NameKeys.map((i: string) => (data[i] = getVariableParam(i)));
    sessionStorage.setItem('dataOrigin', JSON.stringify(data));

    let { ref_pago, tipo_pago, tipo_servicio, indicativo, canal, novum, suscription, utm_medium, utm_source, utm_campaign } = data;
    let dataForState = {
      active: comerce[tipo_pago as keyof comm] || 1,
      indicative: indicativo,
      typeInvoice: indicativo ? 'telefono' : 'factura_id',
      number: ref_pago,
      typeLine: tipo_servicio === 'corporativo' ? 'movil' : tipo_servicio,
      referen: canal,
      novum,
      invoiceType: tipo_servicio === 'corporativo' ? 'corporativo' : ''
    };
    const newExtra1 = {
      Canal: canal,
      Medium: utm_medium,
      Campaign: utm_campaign,
      Source: utm_source,
    }
    return {
        go: ref_pago && tipo_servicio,
        dataForState,
        type: suscription !== "true" ? "consult" : "suscription",
        newExtra1: JSON.stringify(newExtra1)
      };
  }
  return { go: false };
};

// ?tipo_servicio=fijo&tipo_pago=metrotel&_ga=2.20215032.1972795592.1585235178-1956759425.1582147085
// ?tipo_servicio=fijo&canal=app&tipo_pago=movistar&novum=true&urlcallback=aHR0cHM6Ly93d3cubW92aXN0YXIuY28v&ref_pago=2131776109
// ?ref_pago=1039972140&tipo_servicio=movil&tipo_pago=movistar&tipo_servicio=movil
// ?ref_pago=6019955536&tipo_servicio=fijo
// ?tipo_servicio=movil&canal=app&tipo_pago=movistar&novum=true&urlcallback=aHR0cHM6Ly93d3cubW92aXN0YXIuY28v&ref_pago=3216199924

export let validateRoute = (changeLocation: (e: string) => void) => {
  let url = window.location.pathname.split('/');
  let param = url[url.length - 1];
  let extra = `${param.includes('?') ? '&' + param.split('?')[1] : ''}`;
  const search = window.location.search;
  let pathname = window.location.pathname;
  if (!getVariableParam('ref_payco')) {
    switch (pathname) {
      case '/pago_movil':
        changeLocation(`/?tipo_servicio=movil&tipo_pago=movistar${extra}`);
        break;
      case '/pago_fijo':
        changeLocation(`/?tipo_servicio=fijo&tipo_pago=movistar${extra}`);
        break;

      case '/pago_metrotel':
        changeLocation(`/?tipo_servicio=fijo&tipo_pago=metrotel${extra}`);
        break;

      case '/pago_corporativo':
        changeLocation(
          `/corporativo?tipo_servicio=corporativo&tipo_pago=movistar${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;

      case '/pago_telebucaramanga':
        changeLocation(
          `/?tipo_servicio=fijo&tipo_pago=telebucaramanga${extra}`
        );
        break;

      case '/corporativo/pago_movil':
        changeLocation(
          `/corporativo?tipo_servicio=movil&tipo_pago=movistar${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;
      case '/corporativo/pago_fijo':
        changeLocation(
          `/corporativo?tipo_servicio=fijo&tipo_pago=movistar${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;
      case '/corporativo/pago_corporativo':
        changeLocation(
          `/corporativo?tipo_servicio=corporativo&tipo_pago=movistar${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;
      case '/corporativo/pago_metrotel':
        changeLocation(
          `/corporativo?tipo_servicio=fijo&tipo_pago=metrotel${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;
      case '/corporativo/pago_telebucaramanga':
        changeLocation(
          `/corporativo?tipo_servicio=fijo&tipo_pago=telebucaramanga${extra}`
        );
        sessionStorage.setItem('isCorporativo', '1');
        break;
      default:
        if (!getVariableParam('tipo_pago') && search) {
          let active = getVariableParam('active') 
          let option1 =getVariableParam('option1')
          let option2 = getVariableParam('option2')
          let number = getVariableParam('number')
          if( active && option1 && option2 &&number){
              const autoCheckout={
                active,
                option1,
                option2,
                number
              }             
              sessionStorage.setItem('autoCheckout',JSON.stringify(autoCheckout))
          }
          else if (pathname === '/') {
            changeLocation(`/${search}&tipo_pago=movistar&tipo_servicio=movil`);
            sessionStorage.clear();
          } else if (
            pathname === '/' ||
            pathname === '/corporativo' ||
            pathname === '/corporativo/' ||
            pathname === 'corporativo/'
          ) {
            sessionStorage.clear();
            changeLocation(`/corporativo${search}&tipo_pago=movistar`);
            sessionStorage.setItem('isCorporativo', '1');
          }
        } else if (
          pathname === '/corporativo' ||
          pathname === '/corporativo/' ||
          pathname === 'corporativo/'
        ) {
          sessionStorage.clear();
          changeLocation(
            `/corporativo?tipo_servicio=corporativo`
          );
          sessionStorage.setItem('isCorporativo', '1');
        } else if (pathname === '/') {
          sessionStorage.clear();
        }
        break;
    }
  }
};
