import React, {FC} from 'react';
import {View,Text} from "@react-pdf/renderer";
import {DataItem} from "./Dataitem";
import {invoiceResume} from "../../resumeMI/types";
import {checkNumberToString} from "../../helpers";
import {styles} from "./styles";
import {comerceNumber} from "../../../consultForm/Helpers/constants";

type Props = {
    id:number;
    data:invoiceResume
}

const InvoiceItemPdf:FC<Props> = (props) => {

    let filial = comerceNumber[sessionStorage.getItem('filial')||"movistar"];
    let hide = filial !== 1;

    let {id} = props;
    let {
        numeroRecibo,
        descripcion,
        referencia,
        servicioTelefonia,
        servicioMovil,
        servicioAdicional,
        total,
    } = props.data;

    return (
        <View style={styles.detailInvoice}>
            <View ><Text style={styles.textSmallBold}>Factura {id+1}</Text></View>
            <DataItem hide={hide} uppercase={false} amount={false} value={numeroRecibo}      label={"Número de recibo"} />
            <DataItem hide={hide} uppercase={false} amount={false} value={descripcion}       label={"Descripción"} />
            <DataItem             uppercase={false} amount={false} value={ checkNumberToString(referencia)}        label={"Referencia"} />
            <DataItem hide={hide} uppercase={false} amount={true}  value={ checkNumberToString(servicioTelefonia)} label={"Equipo"} />
            <DataItem hide={hide} uppercase={false} amount={true}  value={ checkNumberToString(servicioMovil)}     label={"Servicio"} />
            <DataItem hide={hide} uppercase={false} amount={true}  value={ checkNumberToString(servicioAdicional)} label={"Adicional"} />
            <DataItem             uppercase={false} amount={true}  value={ checkNumberToString(total)}             label={"Valor total"} />
        </View>
    )
}

export default React.memo(InvoiceItemPdf);