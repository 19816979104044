import axios from "axios";
import config from "../../../config/config";

type datos = {
    ref_payco:string;
    email:string;
}

export const SendEmailService = async (data:datos) => {

    let obj:{
       result:boolean;
       [key:string]:any
    }={result:false}

    try {
        await axios({
                data   : data,
                url    :`${config.amonServices}/invoice/voucher`,
                method : 'POST'
        })
            .then((resp:any)=>{ 
            
            
                if(resp.status === 200){
                   if(resp.data.success){
                       obj.result = true;
                       return obj;
                   }
                    obj.message = "Ocurrió un error, intente más tarde.";
                    return obj;
                }
                console.log(":: ERROR CORREO NO ENVIADO ::::");
            })
            .catch(e=>{
                console.log(e, ":: ERROR ENVIANDO CORREO ::::");
                obj.message = ":: ERROR ENVIANDO CORREO ::::";
            })
    }
    catch (e) {
        console.log(e, ":: ERROR INTENTANDO ENVIAR CORREO ::::");
    }

    return obj;
}