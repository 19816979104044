import React, {FC} from "react";
import {Document, Page, View} from "@react-pdf/renderer";
import {styles} from "./styles";
import {FooterBrand, HeaderBrand} from "./headerAndFooterBrand";
import {DataComerce} from "./DataComercePdf";
import {DataTransaction} from "./DataTransaction";
import {comercio, detalleTransaccion, invoiceResume} from "../../resumeMI/types";
import RenderInvoices from "./RenderInvoices";


type Props = {
    data:{
        datacomerce:comercio,
        dataTransaction:detalleTransaccion,
        invoices:invoiceResume[]
    }
}
export const Doc:FC<Props> = (props:any) => {

    let {
        datacomerce,
        dataTransaction,
        invoices,
    } = props.data

    return(
        <Document>
            <Page style={styles.page} size='A4' >
                <View style={styles.dataTransaction}>
                    <HeaderBrand state={dataTransaction.estado}/>
                    <View style={styles.wrapTwoBlocks}>
                        <DataComerce data={datacomerce}/>
                        <DataTransaction data={dataTransaction}/>
                    </View>
                    <RenderInvoices invoices={invoices}/>
                    <View style={styles.spacio}></View>
                    <FooterBrand/>
                </View>
            </Page>
        </Document>
    )
}

