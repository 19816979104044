// @ts-ignore
import {Font, StyleSheet} from "@react-pdf/renderer";
// @ts-ignore
import font  from "../../../../fuentes/telefonicaweb-regular-webfont.ttf";
// @ts-ignore
import fontb from "../../../../fuentes/telefonicaweb-bold-webfont.ttf";

Font.register({
    family: 'Oswald',
    src: font,
});

Font.register({
    family: 'bold',
    src: fontb,
});

export const styles = StyleSheet.create({
    page: {
        flexDirection  : 'column',
        justifyContent : 'flex-start'
    },
    container:{
        display : 'flex',
        flexDirection: 'column',
        width   : "100%",
        padding : '20px',
        height  : '100%',
    },

    icon:{
        height: '30pt',
        width: '40pt',
        margin:'auto',
        marginTop:"20pt"
    },
    title:{
        color     : "#019DF4",
        textAlign : "center",
        width     : "100%",
        padding   : "25px",
        fontFamily: 'Oswald',
    },
    image: {
        width: 'auto',
        height:"60pt",
        padding: 10,
        backgroundColor: 'grey',
    },
    textWrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f0f0f0',
        paddingHorizontal: 50,
        paddingVertical: 30,
    },
    text: {
        fontFamily: 'Oswald',
        color: '#212121',
    },

    textSmall: {
        fontFamily: 'Oswald',
        color: '#212121',
        fontSize:"11pt"
    },
    textSmallBold : {
        fontFamily: 'bold',
        color: '#212121',
        fontSize:"11pt"
    },

    dataItemCont:{
        width          : "45%",
        margin         : "auto",
        display        : "flex",
        justifyContent : "flex-start",
        alignItems     : "flex-start",
        flexDirection  : "row",
        flexWrap       : "wrap",
        flexGrow       : 1,
        flexBasis      : 2,
    },
    dataItem:{
        flexGrow   : 1,
        flexShrink : 0,
        width      : "100%",
        padding    : "4pt 0",
        display    : "flex"
    },
    dataItemLabel:{
        fontSize: "10pt",
    },
    dataItemValue:{
        fontSize : "12pt",
        color    :"#999999",
        fontFamily: 'Oswald',
    },

    footer:{
        display :'flex',
        flexDirection :"row",
        justifyContent: "center",
        fontSize :"10pt",
        alignItems: 'center'
    },
    iconFooter: {
        height : "15pt",
        width  : 'auto',
        margin : '10pt 5pt'
    },
    spacio:{
        flexGrow: 3,
        width: "100%"
    },
    containerInvoices:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        alignItems:"flex-start",
        width:"80%",
        margin:"auto",
        flexWrap:"wrap",
        borderTop:"1pt solid #e4e4e4",
        paddingTop:"10pt",
        marginTop:"10pt"
    },
    detailInvoice:{
        flexGrow     : 1,
        flexShrink   : 0,
        width        : "48%",
        padding      : "8pt 0",
        display      : "flex",
        borderBottom :"1pt solid #e4e4e4"
    },
    wrapTwoBlocks:{
        display       : "flex",
        flexDirection :"row",
        flexGrow      : 1,
        flexShrink    : 0,
        width         : "80%",
        margin        :"auto"
    }


});