import React, {FC} from "react";
import {Text, View} from "@react-pdf/renderer";
import NumberFormat from "react-number-format";
import {styles} from "./styles";

type Props = {
    label:string;
    amount:boolean;
    value:string;
    uppercase:boolean;
    hide?:boolean;
}

export const DataItem:FC<Props> =(props:any)=>{

    let {label,amount,value,uppercase,hide} = props;
    if(value && uppercase) value = value.toUpperCase();
    if(hide) return <View></View>

    return (
        <View style={styles.dataItem}>
            <View style={styles.dataItemLabel}>
                <Text style={styles.text}>{label}</Text>
            </View>
            <View style={styles.dataItemValue}>
                {amount ?
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        disabled={true}
                        value={isNaN(parseInt(props.value)) ? 0 : props.value }
                        displayType={"text"}
                        renderText={(formattedValue)=>   <Text>{formattedValue}</Text>}
                    /> :
                    <Text>
                        {value || 'Sin información.'}
                    </Text>
                }
            </View>
        </View>
    )
}