import { createSlice } from '@reduxjs/toolkit'

export const extrasSlice = createSlice({
    name: 'extras',
    initialState: {
        value: "",
    },
    reducers: {
        setExtras: (state, action) => {
            state.value = action.payload;
        },
    },
})
export const { setExtras } = extrasSlice.actions

export default extrasSlice.reducer