import React, {useEffect, useState} from 'react';
import Select,{components} from "react-select";
import getCities from "./getCities";
import {colors} from "../index";
import Flex from "../flex";

interface Props {
    value?:string;
    placeholder?:string;
    disabled?:boolean;
    icon?:boolean
    setFocus:()=> void;
    setBlur:()=> void;
    setValue:(e:any)=> void;
    error?:boolean;
    Ref?:any
}

type City = {
    id:string;
    nombre:string;
    ciudad_departamento:string;
    indicativo:string;
    departamento:string;
}

type City_reduced = {
    "label":string;
    "value":string;
}


const SelectCities:React.FC<Props> = (props) => {


    type Estado = {
        options:City_reduced[];
        searchValue:string,
        isFocus:boolean
    }

    type Val = null | {
        label:string;
        value:string
    }


    const [MyState,set] = useState<Estado>({
        options:[],
        searchValue:'',
        isFocus:false
    });

    const [Value,setValue] = useState<Val>({
        label:'',
        value:''
    })

    let Mychevron = (props:any) => {
        return (
            <Flex flex={"0 0 30px"} >
                <components.IndicatorsContainer {...props} />
                <i className={"icon-icon-chevron"}> </i>
            </Flex>
        );
    };

    let singleVal = (props:any) => {
        return (

            <components.SingleValue {...props} >
                {props.data.label}
            </components.SingleValue>

        );
    };

    let addcities = ()=> {
        let cantidad = 150;
        let copycities = [...getCities()];
        let citiesN  = copycities.filter( (_,key) => key < cantidad )
        let cities = citiesN.map((item:City)=> ({
            "label": item.nombre + "["+ item.indicativo + "]",
            "value":item.indicativo,
        }))
        set({...MyState, options:cities})
    }

    let filtrar = (e:any) =>{

        let cities = [...getCities()];
        let reduceCities:City_reduced[]= cities.map(obj=>({"label":`${obj.nombre} [ ${obj.indicativo} ]`,"value":obj.indicativo}))
        let nuevascities:City_reduced[] = [];
        if(e){
            nuevascities = reduceCities.filter((item:any) =>
                item.label
                    .normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                    .includes(e.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            )
        }else nuevascities = reduceCities.filter((_,index)=> index < 200)

        set({
            ...MyState,
            options:nuevascities
        })
    }

    const customStyles = {
        control: (provided:any,state:any) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            border:"none",
            borderBottom:`2px solid ${ state.isFocused ? colors.blue : (state.isDisabled ? colors.disabled : colors.blueDark)}`,
            borderRadius:0,
            background:"transparent",
            height:"56px",
            boxShadow:"none",
            '&:hover': {
                borderColor: props.error ? "red" : (state.isFocused ? colors.blue : colors.blueDark),
            },
            '&:disabled': {
                borderColor: colors.disabled,
            },
        }),

        valueContainer: (provided:any,state:any) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            height:"56px",
            paddingLeft: props.icon ? "35px":0
        }),
        singleValue: (provided:any,state:any) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            top:"58%"
        }),
        placeholder: (provided:any,state:any) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            top:"58%"
        }),
    }


    useEffect(()=>{ addcities() },[]);
    useEffect(()=>{
        if(MyState.searchValue.length > 2){
            filtrar(MyState.searchValue);
        }
    },[MyState.searchValue]);

    useEffect(()=>{
        if(!props.value) setValue(null)
    },[props.value])





        return (
           <>
               {/*{JSON.stringify(Value)}*/}
               {/*{MyState.options.length}*/}
               <Select
                   ref={props.Ref}
                   className="basic-single"
                   classNamePrefix="select"
                   isDisabled={props.disabled}
                   isClearable={true}
                   value={Value}
                   onChange={(e:any)=> {
                       setValue(e)
                       props.setValue(e)
                   }}
                   onInputChange={(e) => filtrar(e)}
                   placeholder={"Selecciona tu ciudad"}
                   isSearchable={true}
                   name="label"
                   disabled={props.disabled}
                   options={MyState.options}
                   styles={customStyles}
                   noOptionsMessage={()=>'Sin coincidencias.'}
                   components={{
                       DropdownIndicator:Mychevron,
                       SingleValue:singleVal
                   }}
               />

               </>
        );
}

export default React.memo(SelectCities);
