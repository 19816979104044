import React,{useMemo,FC} from 'react';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';
import Btn from "../../ui/btn";
import {colors} from "../../ui";
import { tagManagerEvent } from '../../../utils/tagManager/tagManagerConfig';
import {Doc} from "./components/Doc";
import {comercio, detalleTransaccion, invoiceResume} from "../resumeMI/types";

type Props = {
    dataToRender:{
        datacomerce:comercio,
        dataTransaction:detalleTransaccion,
        invoices:invoiceResume[]
    };
    disabled:boolean;
}

export const MultiInvoicesPdf:FC<Props> = (props:any) => {

    let {dataToRender,disabled} = props;

    return useMemo(
        () => (
            <PDFDownloadLink
                document={<Doc data={dataToRender} />}
                fileName="comprobante_pago_movistar.pdf"
                className={"wc"}
            >
                {({ loading }) => (loading ?
                        <Btn
                            type={"line"}
                            color={colors.blue}
                            flex={"1 0 100%"}
                            className={"mb-3 mb-md-4 fz18"}
                            onClick={() => null}
                            disabled={true}
                            big
                        >
                            Generando pdf ...
                        </Btn> :
                        <Btn
                            type={"line"}
                            color={colors.blue}
                            flex={"1 0 100%"}
                            className={"mb-3 mb-md-4 fz18"}
                            onClick={() => {} }
                            big
                            disabled={disabled}
                        >
                            Imprimir o guardar
                        </Btn>
                )}
            </PDFDownloadLink>
        ),
        [props],
    )
}