import React from 'react';
import styled from "@emotion/styled";
import NumberFormat from "react-number-format";


interface DataItemP {
    label:string;
    value:string;
    uppercase?:boolean;
    bold?:boolean;
    loading?:boolean;
    amount?:boolean,
    hide?:boolean;
}

const Container =styled.div`
    
    position:relative;
    
    small{
        line-height:1em;
        display:block;
        color:#777;
    }
    p{
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    
    .gost{
        position:absolute;
        z-index:5;
        border-radius:6px;
        height:100%;
        width:100%;
        background: #d1d1d1;
        background: linear-gradient(270deg, #eeeeee, #dbdbdb, #eaeaea);
        background-size: 600% 600%;
    
        -webkit-animation: AnimationName 2s ease-out infinite;
        -moz-animation: AnimationName 2s ease-out infinite;
        -o-animation: AnimationName 2s ease-out infinite;
        animation: AnimationName 2s ease-out infinite;

    }

    @-webkit-keyframes AnimationName {
        0%{background-position:0% 51%}
        50%{background-position:150% 50%}
        100%{background-position:0% 51%}
    }
    @-moz-keyframes AnimationName {
        0%{background-position:0% 51%}
        50%{background-position:150% 50%}
        100%{background-position:0% 51%}
    }
    @-o-keyframes AnimationName {
        0%{background-position:0% 51%}
        50%{background-position:150% 50%}
        100%{background-position:0% 51%}
    }
    @keyframes AnimationName {
        0%{background-position:0% 51%}
        50%{background-position:150% 50%}
        100%{background-position:0% 51%}
    }
`

const Dataitem:React.FC<DataItemP> = (props) => {

    let {hide}= props;

    if(hide) return <div className={"wc"}></div>;

    return (
        <Container className={"wc text-left mb-3 "}>
            {props.loading && <div className="gost"></div>}
            <small className={"fl mb-0"}>{props.label}</small>
            <p className={`mb-0 ${!props.bold && 'fl'}`}>
                { props.amount ?
                <NumberFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    disabled={true}
                    value={isNaN(parseInt(props.value)) ? 0 : props.value }
                    displayType={"text"}
                /> :
                    (props.uppercase ? <span className={'fb'}>{props.value.toUpperCase()}</span> :
                    (props.value || 'Sin información.'))
                }
            </p>
        </Container>
    )
}

export default React.memo(Dataitem);
