import React, {FC} from "react";
import {comercio} from "../../resumeMI/types";
import {View,Text} from "@react-pdf/renderer";
import {styles} from "./styles";
import {DataItem} from "./Dataitem";

type PropsDC = {
    data:comercio
}

export const DataComerce:FC<PropsDC>      = (props)=>{
    let {empresa,nit,fechaHora,ipOrigen} = props.data;
    return (
        <View style={styles.dataItemCont}>
            <DataItem label={"Empresa"}      amount={false} value={empresa}   uppercase={true} />
            <DataItem label={"NIT"}          amount={false} value={nit}       uppercase={false} />
            <DataItem label={"Fecha y hora"} amount={false} value={fechaHora} uppercase={false} />
            <DataItem label={"IP origen"}    amount={false} value={ipOrigen}  uppercase={false} />
        </View>
    )
}