import {setNewTagManager} from '../tagManager';



export const tagManagerEvent = (eventLabel:any, action:any, empresa:any, tipocliente:any) => {

    let dataLayer:any = {
        event: 'trackEvent',
        eventCategory: 'pagar saldo',
        eventAction: action,
        eventLabel: eventLabel,
        empresa_pagos: empresa,
        tipoCliente : tipocliente
    }
    setNewTagManager(dataLayer);

}

export const tagManagerPageView = (pageName:any, empresa:any, tipoCliente:any)=>{
    let dataLayer:any = {
            'event': 'trackPageview', // Nombre del evento que se utiliza en GTM.
            'pageName': pageName, // Path y nombre de la página virtual (String). Requirido.
            'tipoCliente': tipoCliente,
            'empresa_pagos':empresa
    }
    setNewTagManager(dataLayer);
}

export const tagManagerView = (pageName:any, tipoCliente:any) => {
    let dataLayer = {
        'event': 'trackPageview', // Nombre del evento que se utiliza en GTM.
        'pageName': pageName, // Path y nombre de la página virtual (String). Requirido.
        'tipoCliente': tipoCliente
    };
    setNewTagManager(dataLayer);
}

export const productDetailView = (name:any, referecenPago:any, price:any, filial:any) => {

    let dataLayer:any = {
        'event': 'productDetailView', // Nombre del evento que se utiliza en GTM.
        'product':[{
            'name':     'pago ' + name + ' '+ price,
            'id':        referecenPago,
            'price':    `${price}.00`,
            'brand':    'pagos ' +  filial,
            'category': 'pagos',
            'list':     'pagos'
        }]
    }
    setNewTagManager(dataLayer);
}

export const trackAddToCart = (name:any, refPago:any, price:any, empresa:any, quantity:any) => {
    
    let dataLayer:any = {
        'event': 'trackaddtocart', // Nombre del evento que se utiliza en GTM.
        'product':[{
            'name':     'pago ' + name + ' '+ price,
            'id':        refPago,
            'price':    `${price}.00`,
            'brand':    'pagos ' +  empresa,
            'category': 'pagos',
            'list':     'pagos',
            'quantity':  quantity
        }]
    }
    setNewTagManager(dataLayer);
}

export const checkoutView = (name:any, refPago:any, price:any, empresa:any, quantity:any) => {

    let dataLayer:any = {
        'event': 'checkoutView', // Nombre del evento que se utiliza en GTM.
        'product':[{
            'name':     'pago ' + name + ' '+ price,
            'id':        refPago,
            'price':    `${price}.00`,
            'brand':    'pagos ' +  empresa,
            'category': 'pagos',
            'quantity':  quantity
        }],
        'step': '1'
    }
    setNewTagManager(dataLayer);
}

export const trackTransaction = (name:any, refPago:any, price:any, tipocliente:any, quantity:any, medioPago:any) => {

    let dataLayer:any =  {
        'event': 'trackTransaction', // Nombre del evento que se utiliza en GTM.
        'products':[{
            'name':     'pago ' + name + ' '+ price,
            'id':       refPago,
            'price':    `${price}.00`,
            'brand':    'pagos ' +  tipocliente,
            'category': 'pagos',
            'quantity':  quantity
        }]
    }
    setNewTagManager(dataLayer);
}
